<!-- eslint-disable vue/max-len -->
<template>
  <div class="bottom-menu flex justify-between flex-row max-w-screen min-w-screen bg-primary-600 select-none">
    <div class="m-0 p-0">
      <ul class="list-none m-0 p-0 flex">
        <li v-for="menuItem in mainMenu" :key="menuItem.name" class="menuitem flex justify-center flex-col items-center m-1">
          <a @keypress="route(menuItem.href, menuItem.historyHref)" @click="route(menuItem.href, menuItem.historyHref)" :class="{ 'text-white': isRouteActive(menuItem.href), 'bg-primary-400': isRouteActive(menuItem.href) }" class="overflow-hidden overflow-ellipsis relative no-underline flex-1 flex flex-col justify-center text-center p-element items-center cursor-pointer py-2 px-2 hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors">
            <FaIcon class="text-lg mx-1" :class="{ 'fa-shake': menuItem.badge }" v-if="menuItem.icon" :icon="menuItem.icon" />
            <Badge v-if="menuItem.badge" :value="menuItem.badge.value" :severity="menuItem.badge.severity" class="badge" />
            <span class="text-xs">{{ menuItem.name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <Menu ref="userMenu" :model="userMenu" :popup="true" style="width: 250px;" />
    <a @click.prevent="toggleUserMenu" @keypress="toggleUserMenu" class="m-1 overflow-hidden max-w-24 overflow-ellipsis no-underline text-center p-element flex flex-col items-center cursor-pointer px-2 py-2 justify-center hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors">
      <FaIcon class="mx-1" icon="user-circle" />
      <span class="text-sm max-w-16 whitespace-nowrap overflow-hidden overflow-ellipsis text-sm">{{ currentUserName }}</span>
    </a>
  </div>
</template>

<style lang="scss" scoped>
.bottom-menu {
  padding-bottom: env(safe-area-inset-bottom) !important;
}
.badge {
  position: absolute !important;
  font-size: 0.6rem !important;
  min-width: 0.6rem;
  height: 0.6rem;
  line-height: 0.6rem;
  top: 10px;
  right: 33%;
  transform: translate(50%,-50%);
  transform-origin: 100% 0;
  margin: 0;
  padding: 0 0.2rem !important;
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Badge from 'primevue/badge';
import Menu from 'primevue/menu';
import { MenuItem } from 'primevue/menuitem';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';

import { MainLayoutBottomMenuItem, MainLayoutMenuItemRoute } from './interfaces';

@Component({
  components: { FaIcon, Menu, Badge },
})
class BottomMenu extends ComponentBase {
  declare public $refs: { userMenu: InstanceType<typeof Menu> };

  @Prop({ type: Object as PropType<MenuItem[]>, required: false, default: () => [] })
  declare userMenu: MenuItem[];

  @Prop({ type: Object as PropType<MainLayoutBottomMenuItem[]>, required: false, default: () => [] })
  declare menu: MainLayoutBottomMenuItem[];

  public isRouteActive(href: MainLayoutMenuItemRoute): boolean {
    const activeRouteName = (this.$route.name ?? '') as string;
    return activeRouteName.startsWith(href.name);
  }

  public get mainMenu(): MainLayoutBottomMenuItem[] {
    return this.menu.filter((e) => !e.hide);
  }

  public get currentUserName() {
    // @TODO: find user from roles
    return this.$t('User');
  }

  public toggleUserMenu(event: Event) {
    this.$refs.userMenu.toggle(event);
  }

  public async route(to: MainLayoutMenuItemRoute, historyTo?: MainLayoutMenuItemRoute | null) {
    // if we don't have history or we click again on same button, redirect to default
    if (!historyTo || String(this.$route.name).startsWith(to.name)) {
      await this.$router.push(to);
    } else {
      await this.$router.push(historyTo);
    }
  }
}

export default toNative(BottomMenu);
</script>
