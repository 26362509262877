<template>
  <Page>
    <PageActionBar>
      <PageGripMenu :menu="gripMenu" />
    </PageActionBar>
    <PageContent>
      <BlueprintDataView class="mt-2" :title="$t('My Notifications')" sortBy="createdAt" ref="blueprint" noNew :filter="filter" id="notifications" :opts="opts">
        <template #new="{ length }">
          <Button v-if="length && !!unreadNotifications" @click="markAllAsRead" size="small" icon="pi pi-check" text :label="$t('Mark all as read')" />
        </template>
        <template #empty>
          <UndrawMessageSent class="mb-3" primary-color='var(--lar-color-primary)' height="9rem" />
          <div class="mt-2">
            <ContentTitle>{{ $route.name === 'notifications.new' ? $t('No unread notifications') : $t('No notifications')}}</ContentTitle>
          </div>
        </template>
        <template #list="{ data }">
          <NotificationListItem @click="openNotification(data)" :notification="data" />
        </template>
      </BlueprintDataView>
    </PageContent>
  </Page>
</template>

<script lang="ts">
import { BAQuery } from '@larva.io/blueapi-query';
import { ReportEvent } from '@larva.io/report-definitions';
import Button from 'primevue/button';
import { v4 } from 'uuid';
import UndrawMessageSent from 'vue-undraw/UndrawMessageSent';

import { Component, ComponentBase, toNative } from '@/component-base';
import BlueprintDataView from '@/components/blueprint/blueprint-data-view.vue';
import { BlueprintFilter, FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import BARequestHelper from '@/components/helpers/blueapi';
import { BlueprintHelper, BlueprintOptions } from '@/components/helpers/blueprint';
import ContentTitle from '@/components/page/content-title.vue';
import Page from '@/components/page/page.vue';
import PageActionBar from '@/components/page/page-action-bar.vue';
import PageContent from '@/components/page/page-content.vue';
import PageGripMenu from '@/components/page/page-grip-menu.vue';
import { SideMenuItem } from '@/components/page/page-side-menu.interfaces';

import NotificationListItem from './components/notification-list-item.vue';
import { PushNotification } from './interfaces';

@Component({
  components: {
    Button,
    Page,
    PageContent,
    BlueprintDataView,
    UndrawMessageSent,
    PageGripMenu,
    NotificationListItem,
    PageActionBar,
    ContentTitle,
  },
})
class Notifications extends ComponentBase {
  declare $refs: { blueprint: InstanceType<typeof BlueprintDataView> };

  public created() {
    this.eventBus.emit('poll-notifications');
  }

  public get gripMenu(): SideMenuItem[] {
    return [
      {
        name: this.$t('All'),
        href: { name: 'notifications.all' },
      },
      {
        name: this.$t('Unread'),
        href: { name: 'notifications.new' },
      },
    ];
  }

  public get filter(): BlueprintFilter {
    if (this.$route.name === 'notifications.new') {
      return this.newNotificationsFilter;
    }
    return {};
  }

  public get unreadNotifications() {
    return this.AppState.unreadNotifications;
  }

  // eslint-disable-next-line class-methods-use-this
  private get newNotificationsFilter(): BlueprintFilter {
    return {
      readAt: {
        value: null,
        matchMode: FilterMatchMode.EQUALS,
      },
    };
  }

  public get opts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: 'orgs/:orgId/push-notifications',
      params: { orgId: String(this.currentOrgId) },
    };
  }

  public async openNotification(notification: PushNotification) {
    this.RequestsState.globalLoading(true);
    let name = 'properties.property';
    const params: Record<string, string> = { propertyId: notification.log.propertyId, orgId: notification.log.orgId };
    if (notification.log.associations.ticketId) {
      name = 'properties.property.beetle.tickets.ticket';
      params.id = notification.log.associations.ticketId;
    }
    if (notification.log.associations.taskId) {
      name = 'properties.property.beetle.tasks.task';
      params.id = notification.log.associations.taskId;
    }
    if (notification.log.associations.alarmId) {
      name = 'properties.property.measurements.alarms.alarm';
      params.id = notification.log.associations.alarmId;
    }
    if (notification.log.code === ReportEvent.REQUESTED_TO_JOIN_PROPERTY) {
      name = 'properties.property.orgs.settings.managers.manager.approve';
      params.id = notification.log.associations.propertyRoleId ?? v4();
    }
    await this.markAsRead(notification);
    await this.$router.push({ name, params });
    this.RequestsState.globalLoading(false);
  }

  public async markAsRead(notification: PushNotification) {
    const filter: BlueprintFilter = {};
    if (notification.log.associations.ticketId) {
      filter['log.associations.ticketId'] = {
        value: notification.log.associations.ticketId,
        matchMode: FilterMatchMode.EQUALS,
      };
    }
    if (notification.log.associations.taskId) {
      filter['log.associations.taskId'] = {
        value: notification.log.associations.taskId,
        matchMode: FilterMatchMode.EQUALS,
      };
    }
    if (notification.log.associations.alarmId) {
      filter['log.associations.alarmId'] = {
        value: notification.log.associations.alarmId,
        matchMode: FilterMatchMode.EQUALS,
      };
    }
    if (Object.keys(filter).length) { // we have blk mark as read filter
      await BlueprintHelper.getMany(this.opts, { ...filter, ...this.newNotificationsFilter }, { limit: 1000 })
        .then(({ data }) => data.data)
        .then((data) => {
          if (!data.length) {
            return this.mantis.post(`/orgs/${this.currentOrgId}/push-notifications/${notification.id}/read`);
          }
          const updateFilter: BlueprintFilter = {
            id: {
              value: data.map((v) => v.id),
              matchMode: FilterMatchMode.IN,
            },
          };
          const updateParams = BAQuery.createQueryObject({ filter: BARequestHelper.generateFilter(updateFilter) });
          return this.mantis.post(`/orgs/${this.currentOrgId}/push-notifications/read`, { params: updateParams });
        })
        .catch((err) => console.error(err)); // suppress
    } else { // if we don't have bulk mark as read update filter
      await this.mantis.post(`/orgs/${this.currentOrgId}/push-notifications/${notification.id}/read`)
        .catch((err) => this.error(err));
    }
    this.eventBus.emit('poll-notifications');
  }

  public async markAllAsRead() {
    await this.mantis.post(`/orgs/${this.currentOrgId}/push-notifications/read`)
      .catch((err) => this.error(err));
    this.eventBus.emit('poll-notifications');
    await this.$refs.blueprint.initData(false);
  }
}

export default toNative(Notifications);
</script>
