<template>
  <div class="notification-content">
    <!-- User-->
    <span class="font-semibold">
      <BlueprintItem v-if="notification.log.associations.accessGroupUserId" :id="notification.log.associations.accessGroupUserId" :opts="accessGroupUserOpts">
        <template #value="{ item }">
          {{ item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email }}
          <span>&nbsp;</span>
        </template>
      </BlueprintItem>
      <BlueprintItem v-else-if="notification.log.associations.accessGroupLicensePlateId" :id="notification.log.associations.accessGroupLicensePlateId" :opts="accessGroupPlateAssociationOpts">
        <template #value="{ item }">
          {{ item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email }}
          <span>&nbsp;</span>
        </template>
      </BlueprintItem>
      <BlueprintItem v-else-if="notification.log.associations.accessGroupCardId" :id="notification.log.associations.accessGroupCardId" :opts="accessGroupPlateAssociationOpts">
        <template #value="{ item }">
          {{ item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email }}
          <span>&nbsp;</span>
        </template>
      </BlueprintItem>
      <BlueprintItem v-else-if="notification.log.associations.orgRoleId" :id="notification.log.associations.orgRoleId" :opts="usersOpts">
        <template #value="{ item }">
          {{ item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email }}
          <span>&nbsp;</span>
        </template>
      </BlueprintItem>
      <BlueprintItem v-else-if="notification.log.associations.propertyRoleId" :id="notification.log.associations.propertyRoleId" :opts="propertyRoleOpts">
        <template #value="{ item }">
          {{ item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email }}
          <span>&nbsp;</span>
        </template>
      </BlueprintItem>
      <BlueprintItem v-else-if="notification.log.associations.externalPartnerId" :id="notification.log.associations.externalPartnerId" :opts="externalPartnersOpts">
        <template #value="{ item }">
          {{ item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email }}
          <span>&nbsp;</span>
        </template>
      </BlueprintItem>
      <!-- <BlueprintItem v-else-if="notification.log.associations.ruleActionId" :id="notification.log.associations.ruleActionId" :opts="ruleActionsOpts">
          <template #value="{ item }">
            {{ item.name }}
          </template>
          </BlueprintItem>
        -->
      <span v-else>
        <BlueprintItem
          v-if="notification.log.associations.commentId && (notification.log.associations.taskId || notification.log.associations.ticketId)"
          :id="notification.log.associations.commentId"
          :opts="commentsOpts(notification.log.associations.ticketId, notification.log.associations.taskId)"
        >
          <template #value="{ item }">
            <span v-if="item.externalData?.from || item.externalData?.email">{{ item.externalData.from ?? item.externalData?.email}} </span>
            <span v-else>{{ $t('Unknown User') }}</span>
            <span>&nbsp;</span>
          </template>
        </BlueprintItem>
        <BlueprintItem v-else-if="notification.log.associations.taskId" :id="notification.log.associations.taskId" :opts="tasksOpts">
          <template #value="{ item }">
            <span v-if="item.externalData?.from || item.externalData?.email">{{ item.externalData.from ?? item.externalData?.email}} </span>
            <span v-else>{{ $t('Unknown User') }}</span>
            <span>&nbsp;</span>
          </template>
        </BlueprintItem>
        <BlueprintItem v-else-if="notification.log.associations.ticketId" :id="notification.log.associations.ticketId" :opts="ticketsOpts">
          <template #value="{ item }">
            <span v-if="item.externalData?.from || item.externalData?.email">{{ item.externalData.from ?? item.externalData?.email}} </span>
            <span v-else>{{ $t('Unknown User') }}</span>
            <span>&nbsp;</span>
          </template>
        </BlueprintItem>
        <template v-else-if="notification.log.associations.alarmId">
          <!-- suppress -->
        </template>
        <template v-else>
          {{ $t('Unknown User') }}
          <span>&nbsp;</span>
        </template>
      </span>
    </span>
    <span>
      <span>{{ getEventMessage(notification.log.code) }}</span>
      <span>&nbsp;</span>
    </span>
    <BlueprintItem class="font-semibold" :id="notification.log.propertyId" :opts="propertyOpts">
      <template #value="{ item }">
        {{ item.name }}
      </template>
    </BlueprintItem>
    <span>
      <BlueprintItem class="pr-1 whitespace-nowrap overflow-ellipsis overflow-hidden" v-if="notification.log.associations.ticketId" :id="notification.log.associations.ticketId" :opts="ticketsOpts">
        <template #value="{ item }">
          {{ $t('Maintenance Request') }}
          <span class="font-semibold">#{{ item.number }} - {{ item.title }}</span>
          <span v-if="notification.log.associations.commentId"><span>&nbsp;</span>{{ $t('comment') }}.</span>
          <span v-else>&nbsp;</span>
        </template>
      </BlueprintItem>
      <BlueprintItem class="pr-1 whitespace-nowrap overflow-ellipsis overflow-hidden" v-if="notification.log.associations.taskId" :id="notification.log.associations.taskId" :opts="tasksOpts">
        <template #value="{ item }">
          {{ $t('Maintenance Task') }}
          <span class="font-semibold">#{{ item.number }} - {{ item.name }}</span>
          <span v-if="notification.log.associations.commentId"><span>&nbsp;</span>{{ $t('comment') }}.</span>
          <span v-else>&nbsp;</span>
        </template>
      </BlueprintItem>
      <BlueprintItem class="pr-1 whitespace-nowrap overflow-ellipsis overflow-hidden" v-if="notification.log.associations.alarmId" :id="notification.log.associations.alarmId" :opts="alarmsOpts">
        <template #value="{ item }">
          <div>
            <AlarmPriorityBadge :alarm="item" />&nbsp;

            <span v-if="item.analyticAlarmDefinition">
              {{ item.analyticAlarmDefinition.name }}&nbsp;
            </span>
            <span v-if="item.deviceAlarmDefinition">
              {{ item.deviceAlarmDefinition.name }}&nbsp;
            </span>
            <div class="flex items.center" v-if="item.backgroundJobId">
              {{ $t('Job') }} <BlueprintItem class="ml-1 inline-block" :id="item.backgroundJobId" :opts="backgroundJobsOpts" />&nbsp;{{ $t('error') }}
            </div>
            <div class="flex items.center" v-if="item.serviceConnectionId">
              {{ $t('App integration') }} <BlueprintItem class="ml-1 inline-block" :id="item.serviceConnectionId" :opts="serviceConnectionOpts" />&nbsp;{{ $t('error') }}
            </div>
            <span>&nbsp;</span>
          </div>
        </template>
      </BlueprintItem>
      <span v-if=notification.log.data?.changed>{{ notification.log.data.changed.filter((f: string) => f !== 'updatedAt' && f !== 'orgRoleId').map((f: string) => getChangeLabel(f)).join(', ') }}.</span>
    </span>
  </div>
</template>

<style lang="scss">
  .notification-content > * * {
    white-space: normal !important;
    overflow: visible !important;
    display: inline-block !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    white-space: normal !important;
  }
  .notification-content * .whitespace-nowrap {
    white-space: normal !important;
  }
  .notification-content > * {
    display: inline !important;
    flex-direction: column;
  }
  .notification-content {
    line-height: 1.3rem;
    display: block;
  }
</style>

<script lang="ts">
import { getEventMessage, ReportEvent } from '@larva.io/report-definitions';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';
import BlueprintItem from '@/components/blueprint/blueprint-item.vue';
import { capitalize } from '@/components/formatters/utils';
import { BlueprintOptions } from '@/components/helpers/blueprint';
import AlarmPriorityBadge from '@/modules/roles/org-role-property-role/modules/property-role/modules/measurements/components/alarm-priority-badge.vue';

import { PushNotification } from '../interfaces';

@Component({
  components: {
    BlueprintItem,
    AlarmPriorityBadge,
  },
})
class NotificationContent extends ComponentBase {
  @Prop({ type: Object as PropType<PushNotification>, required: true })
  declare public notification: PushNotification;

  public getEventMessage(t: ReportEvent) {
    return this.$t(capitalize(getEventMessage(t)));
  }

  public getChangeLabel(changeField: string) {
    let label = '';
    switch (changeField) {
      case 'assignedToId': {
        label = this.$t('Assigned To');
        break;
      }
      case 'supervisedById': {
        label = this.$t('Supervised By');
        break;
      }
      case 'unitId': {
        label = this.$t('Unit');
        break;
      }
      default: {
        label = this.$t(changeField);
      }
    }
    return label;
  }

  public commentsOpts(ticketId?: string|null, taskId?: string|null): BlueprintOptions {
    if (!ticketId && !taskId) {
      throw new Error('Invalid comment opts input');
    }
    const entity = ticketId ? 'tickets' : 'tasks';
    return {
      http: this.beetle,
      apiRoutePath: `orgs/:orgId/properties/:propertyId/${entity}/:itemId/comments`,
      params: {
        orgId: this.notification.log?.orgId || '',
        propertyId: this.notification.log?.propertyId || '',
        itemId: String(ticketId ?? taskId),
      },
    };
  }

  public get usersOpts(): BlueprintOptions<{ email: string }> {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/org-roles',
      params: { orgId: this.notification.log?.orgId },
    };
  }

  public get propertyRoleOpts(): BlueprintOptions<{ email: string }> {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/property-roles',
      params: { orgId: this.notification.log?.orgId, propertyId: this.notification.log?.propertyId },
    };
  }

  public get accessGroupUserOpts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/access-group-users',
      params: { orgId: this.notification.log?.orgId ?? '', propertyId: this.notification.log?.propertyId ?? '' },
    };
  }

  public get externalPartnersOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/external-partners',
      params: { orgId: this.notification.log?.orgId ?? '', propertyId: this.notification.log?.propertyId ?? '' },
    };
  }

  public get accessGroupPlateAssociationOpts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/access-group-license-plates',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public get accessGroupCardAssociationOpts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/access-group-cards',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }
  /*
  public get ruleActionsOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/rule-actions',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
  }
  */

  public get propertyOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties',
      params: { orgId: String(this.currentOrgId) },
    };
  }

  public get ticketsOpts(): BlueprintOptions {
    return {
      http: this.beetle,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/tickets',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public get tasksOpts(): BlueprintOptions {
    return {
      http: this.beetle,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/tasks',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public get alarmsOpts(): BlueprintOptions {
    return {
      http: this.measurements,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/alarms',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public get serviceConnectionOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/service-connections',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public get backgroundJobsOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/background-jobs',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }
}

export default toNative(NotificationContent);
</script>
