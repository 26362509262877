/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

import Home from './home.vue';

export default [
  {
    path: '/org-role-property-role/orgs/:orgId/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tasks',
    name: 'home.tasks',
    redirect: { name: 'home.tasks.ongoing' },
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tasks/ongoing',
    name: 'home.tasks.ongoing',
    component: () => import('./tasks.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tasks/recurring',
    name: 'home.tasks.recurring',
    component: () => import('./tasks.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tasks/done',
    name: 'home.tasks.done',
    component: () => import('./tasks.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tasks/overdue',
    name: 'home.tasks.overdue',
    component: () => import('./tasks.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/properties/:propertyId/tasks/:id',
    name: 'home.tasks.task',
    component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/beetle/tasks-item.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tickets',
    name: 'home.tickets',
    redirect: { name: 'home.tickets.ongoing' },
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tickets/new',
    name: 'home.tickets.new',
    component: () => import('./tickets.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/tickets/ongoing',
    name: 'home.tickets.ongoing',
    component: () => import('./tickets.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/properties/:propertyId/tickets/:id',
    name: 'home.tickets.ticket',
    component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/beetle/tickets-item.vue'),
  },
  //
  {
    path: '/org-role-property-role/orgs/:orgId/alarms',
    name: 'home.alarms',
    redirect: { name: 'home.alarms.important' },
  },
  {
    path: '/org-role-property-role/orgs/:orgId/alarms/critical',
    name: 'home.alarms.critical',
    component: () => import('./alarms.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/alarms/ack',
    name: 'home.alarms.ack',
    component: () => import('./alarms.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/alarms/important',
    name: 'home.alarms.important',
    component: () => import('./alarms.vue'),
  },
  {
    path: '/org-role-property-role/orgs/:orgId/properties/:propertyId/alarms/:id',
    name: 'home.alarms.alarm',
    component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/measurements/alarms-item.vue'),
  },
  //
  {
    path: '/org-role-property-role/orgs/:orgId/properties/:propertyId/technical-systems/:id',
    name: 'home.technical-systems.technical-system',
    component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/measurements/technical-systems-item.vue'),
  },
] satisfies RouteRecordRaw[];
