import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex items-center cursor-pointer hover:bg-surface-50 py-3 px-2 md:px-3" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_3 = { class: "overflow-hidden ml-1 md:ml-2 flex-grow flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "text-xs sm:text-sm font-bold whitespace-nowrap uppercase overflow-hidden overflow-ellipsis"
}
const _hoisted_5 = {
  key: 1,
  class: "flex items-center justify-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.faIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["resource rounded-full aspect-square p-1 md:p-2 text-sm md:text-lg !leading-none flex justify-center items-center", `resource-${_ctx.variant}`])
          }, [
            _createVNode(_component_FaIcon, {
              fixedWidth: "",
              icon: _ctx.faIcon
            }, null, 8, ["icon"])
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.faIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Badge, {
            severity: "secondary",
            class: "mr-1 md:mr-2",
            value: _ctx.value
          }, null, 8, ["value"])
        ]))
      : _createCommentVNode("", true)
  ]))
}