import { RouteRecordRaw } from 'vue-router';

import ReportsLayout from './layouts/reports.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/org-role/orgs/:orgId/reports',
    name: 'reports',
    component: ReportsLayout,
    redirect: { name: 'reports.tasks' },
    children: [
      {
        name: 'reports.tasks',
        path: '/org-role/orgs/:orgId/reports/tasks',
        component: () => import('./tasks.vue'),
      },
      {
        name: 'reports.tickets',
        path: '/org-role/orgs/:orgId/reports/tickets',
        component: () => import('./tickets.vue'),
      },
    ],
  },
];

export default routes;
