import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentTitle = _resolveComponent("ContentTitle")!
  const _component_SummaryCardItem = _resolveComponent("SummaryCardItem")!
  const _component_SummaryCardCol = _resolveComponent("SummaryCardCol")!
  const _component_SummaryCardGrid = _resolveComponent("SummaryCardGrid")!
  const _component_SummaryCard = _resolveComponent("SummaryCard")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Grid = _resolveComponent("Grid")!

  return (!_ctx.loading)
    ? (_openBlock(), _createBlock(_component_Grid, {
        key: 0,
        col: "1",
        md: _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) || _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS) ? 5 : 1
      }, {
        default: _withCtx(() => [
          (_ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) || _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS))
            ? (_openBlock(), _createBlock(_component_Col, {
                key: 0,
                col: "1",
                md: "3",
                class: "md:mr-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ContentTitle, { class: "!mt-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Maintenance Requests & Tasks')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_SummaryCard, { class: "overflow-hidden" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SummaryCardGrid, { col: "12" }, {
                        default: _withCtx(() => [
                          (_ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS))
                            ? (_openBlock(), _createBlock(_component_SummaryCardCol, {
                                key: 0,
                                col: _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) && _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS) ? 6 : 12,
                                class: "!p-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SummaryCardItem, {
                                    value: _ctx.newTickets ?? 0,
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open('home.tickets.new'))),
                                    variant: _ctx.newTickets ? 'danger' : 'dark',
                                    faIcon: "fa-person-circle-exclamation",
                                    title: _ctx.$t('New Requests')
                                  }, null, 8, ["value", "variant", "title"])
                                ]),
                                _: 1
                              }, 8, ["col"]))
                            : _createCommentVNode("", true),
                          (_ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS))
                            ? (_openBlock(), _createBlock(_component_SummaryCardCol, {
                                key: 1,
                                col: _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) && _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS) ? 6 : 12,
                                class: "!p-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SummaryCardItem, {
                                    value: _ctx.activeTickets ?? 0 ,
                                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open('home.tickets.ongoing'))),
                                    variant: _ctx.activeTickets ? 'info' : 'dark',
                                    faIcon: "fa-person-running",
                                    title: _ctx.$t('Active Requests')
                                  }, null, 8, ["value", "variant", "title"])
                                ]),
                                _: 1
                              }, 8, ["col"]))
                            : _createCommentVNode("", true),
                          (_ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS))
                            ? (_openBlock(), _createBlock(_component_SummaryCardCol, {
                                key: 2,
                                col: _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) && _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS) ? 6 : 12,
                                class: "border-t border-surface !p-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SummaryCardItem, {
                                    value: _ctx.overdueTasks ?? 0 ,
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open('home.tasks.overdue'))),
                                    variant: _ctx.overdueTasks ? 'danger' : 'dark',
                                    faIcon: "clipboard-question",
                                    title: _ctx.$t('Overdue Tasks')
                                  }, null, 8, ["value", "variant", "title"])
                                ]),
                                _: 1
                              }, 8, ["col"]))
                            : _createCommentVNode("", true),
                          (_ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS))
                            ? (_openBlock(), _createBlock(_component_SummaryCardCol, {
                                key: 3,
                                col: _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) && _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS) ? 6 : 12,
                                class: "border-t border-surface !p-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SummaryCardItem, {
                                    value: _ctx.confirmTasks ?? 0 ,
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.open('home.tasks.done'))),
                                    variant: _ctx.confirmTasks ? 'warning' : 'dark',
                                    faIcon: "check-double",
                                    title: _ctx.$t('Tasks To Review')
                                  }, null, 8, ["value", "variant", "title"])
                                ]),
                                _: 1
                              }, 8, ["col"]))
                            : _createCommentVNode("", true),
                          (_ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS))
                            ? (_openBlock(), _createBlock(_component_SummaryCardCol, {
                                key: 4,
                                col: _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) && _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS) ? 6 : 12,
                                class: "border-t border-surface !p-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SummaryCardItem, {
                                    value: _ctx.activeTasks ?? 0,
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.open('home.tasks.ongoing'))),
                                    variant: _ctx.activeTasks ? 'info' : 'dark',
                                    faIcon: "fa-clipboard",
                                    title: _ctx.$t('Ongoing Tasks')
                                  }, null, 8, ["value", "variant", "title"])
                                ]),
                                _: 1
                              }, 8, ["col"]))
                            : _createCommentVNode("", true),
                          (_ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS))
                            ? (_openBlock(), _createBlock(_component_SummaryCardCol, {
                                key: 5,
                                col: _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TICKETS) && _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TASKS) ? 6 : 12,
                                class: "border-t border-surface !p-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SummaryCardItem, {
                                    value: _ctx.recurringTasks ?? 0,
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.open('home.tasks.recurring'))),
                                    variant: _ctx.recurringTasks ? 'success' : 'danger',
                                    faIcon: "calendar",
                                    title: _ctx.$t('Periodic Tasks')
                                  }, null, 8, ["value", "variant", "title"])
                                ]),
                                _: 1
                              }, 8, ["col"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Col, {
            col: "1",
            md: "2",
            class: "mt-2 md:mt-0 md:ml-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ContentTitle, { class: "md:!mt-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Alarms')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_SummaryCard, null, {
                default: _withCtx(() => [
                  _createVNode(_component_SummaryCardGrid, { col: "1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SummaryCardCol, {
                        col: "1",
                        class: "!p-0 !border-x-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SummaryCardItem, {
                            value: _ctx.criticalAlarms ?? 0 ,
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.open('home.alarms.critical'))),
                            variant: _ctx.criticalAlarms ? 'danger' : 'dark',
                            faIcon: "exclamation-triangle",
                            title: _ctx.$t('Critical')
                          }, null, 8, ["value", "variant", "title"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_SummaryCardCol, {
                        col: "1",
                        class: "border-t border-b !border-x-0 border-surface !p-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SummaryCardItem, {
                            value: _ctx.importantAlarms ?? 0 ,
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.open('home.alarms.important'))),
                            variant: _ctx.importantAlarms ? 'warning' : 'dark',
                            faIcon: "circle-exclamation",
                            title: _ctx.$t('Important')
                          }, null, 8, ["value", "variant", "title"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_SummaryCardCol, {
                        col: "1",
                        class: "!p-0 !border-x-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SummaryCardItem, {
                            value: _ctx.confirmAlarms ?? 0,
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.open('home.alarms.ack'))),
                            variant: _ctx.confirmAlarms ? 'warning' : 'dark',
                            faIcon: "check-double",
                            title: _ctx.$t('To Review')
                          }, null, 8, ["value", "variant", "title"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["md"]))
    : _createCommentVNode("", true)
}