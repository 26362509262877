<template>
  <div>
    <ContentTitle class="!mt-0">{{ $t('My Buildings') }}</ContentTitle>
    <Card class="property-list px-1 pb-3">
      <template #content>
        <div class="pt-1 md:pt-2 flex items-center justify-center">
          <div class="flex flex-nowrap overflow-x-scroll py-4 hide-scroll-bar" v-if="!loading && properties.length">
            <template v-for="(item) of properties" :key="item.id">
              <div class="inline-bloc px-2 cursor-pointer" @keypress="$router.push({ name: 'properties.property', params: { propertyId: item.id } })" @click="$router.push({ name: 'properties.property', params: { propertyId: item.id } })">
                <div class="flex items-center justify-center">
                  <div class="resource resource-dark">
                    <FaIcon icon="fa-regular fa-building" />
                  </div>
                </div>
                <div class="px-4 text-center mt-2 text-xs md:text-sm uppercase line-clamp-2 text-sm font-bold">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </div>
          <div v-else-if="!loading && !properties.length" class="text-center m-4">
            <UndrawBuilding class="mb-3" primary-color='var(--lar-color-primary)' height="3rem" />
            <div class="mt-2">
              <ContentTitle>{{ $t('You haven’t joined any buildings yet') }}</ContentTitle>
              <div>{{ $t('Join a building to begin your management responsibilities.') }}</div>
            </div>
          </div>
          <div v-else>
            <template v-if="loading"><Skeleton v-for="index in 3" :key="index" height="4rem" class="inline-block min-w-32 max-w-32 mx-2 rounded-md" /></template>
          </div>
        </div>
      </template>
    </Card>
    <div class="text-right pt-2" v-if="orgRoleAvailable">
      <Button :label="$t('All buildings')" iconPos="right" icon="pi pi-chevron-right" size="small" text @click="$router.push({ name: 'properties' })" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.property-list {
  border-color: var(--lar-background-color);
  display: flex;
  flex-direction: column;
}
.property-list .p-card-body, .property-list .p-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.property-list .p-card-content {
  justify-content: center !important;
}
.resource {
  width: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  height: 3.5rem;
  font-size: 1.3rem;
  border-radius: 50%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.resource-dark {
  background-color: var(--lar-color-dark);
  color: var(--lar-color-dark-contrast);
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Skeleton from 'primevue/skeleton';
import UndrawBuilding from 'vue-undraw/UndrawBuilding';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';
import { BlueprintFilter, FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import { BlueprintHelper, BlueprintOptions } from '@/components/helpers/blueprint';
import ContentTitle from '@/components/page/content-title.vue';
import { Property } from '@/store/modules/interfaces';

@Component({
  components: {
    FaIcon,
    Card,
    Avatar,
    Button,
    Skeleton,
    UndrawBuilding,
    ContentTitle,
  },
})
class HomePropertyList extends ComponentBase {
  @Prop({ type: Array as PropType<string[]>, default: () => [] })
  declare propertyIds: string[];

  public properties: Property[] = [];

  public loading = false;

  public async created() {
    await this.getProperties();
  }

  public get orgRoleAvailable() {
    return this.currentOrgId && this.me ? this.me.getOrg(this.currentOrgId)?.hasOrgRole() : false;
  }

  private async getProperties() {
    try {
      this.loading = true;
      if (!this.propertyIds.length) {
        this.properties = [];
      } else {
        // filter properties where I have property role
        const filters: BlueprintFilter = {
          id: {
            value: this.propertyIds,
            matchMode: FilterMatchMode.IN,
          },
        };
        const { data: { data: properties } } = await BlueprintHelper.getMany(this.propertyOpts, filters, { limit: 500 });
        this.properties = properties;
      }
    } catch (err) {
      this.error(err);
    } finally {
      this.loading = false;
    }
  }

  public get propertyOpts(): BlueprintOptions<Property> {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties',
      params: { orgId: String(this.currentOrgId) },
      fields: [
        { field: 'id' },
        { field: 'name', searchable: true },
        { field: 'line1', searchable: true },
        { field: 'line2', searchable: true },
        { field: 'countrySubdivisionCode', searchable: true },
        { field: 'postalCode', searchable: true },
      ],
    };
  }
}

export default toNative(HomePropertyList);
</script>
