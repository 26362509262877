/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

import Notifications from './notifications.vue';

export default [
  {
    path: '/org-role/orgs/:orgId/notifications',
    name: 'notifications',
    redirect: { name: 'notifications.all' },
  },
  {
    path: '/org-role/orgs/:orgId/notifications/all',
    name: 'notifications.all',
    component: Notifications,
  },
  {
    path: '/org-role/orgs/:orgId/notifications/new',
    name: 'notifications.new',
    component: Notifications,
  },
] as RouteRecordRaw[];
