import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/org-role/orgs/:orgId',
    name: 'org',
    component: () => import('./organization.vue'),
  },
  {
    path: '/org-role/orgs/:orgId/billing',
    name: 'org.billing',
    component: () => import('./billing.vue'),
  },
  {
    path: '/org-role/orgs/:orgId/managers',
    name: 'org.managers',
    component: () => import('./managers-list.vue'),
  },
  {
    path: '/org-role/orgs/:orgId/managers/:id',
    name: 'org.managers.manager',
    component: () => import('./managers-item.vue'),
  },
  {
    path: '/org-role/orgs/:orgId/message-templates/:id',
    name: 'org.message-templates.template',
    component: () => import('./message-templates-item.vue'),
  },
] as RouteRecordRaw[];
