/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

import PropertiesRoutes from './modules/properties/routes';

export default [
  {
    path: '/access-group-user',
    name: 'access-group-user',
    component: () => import('./layouts/access-group-user.vue'),
    redirect: { name: 'init' },
    children: [
      {
        path: '/access-group-user/orgs/:orgId',
        name: 'access-group-user.home',
        redirect: { name: 'access-group-user.properties' },
      },
      ...PropertiesRoutes,
    ],
  },
] satisfies RouteRecordRaw[];
