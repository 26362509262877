<template>
  <div :class="[getStateClass(alarm.state)]" class="whitespace-nowrap overflow-ellipsis overflow-hidden">
    <Item :faIcon="alarm.state === 'active' ? 'circle' : 'check'" :label="getAlarmStateValue(alarm.state)" />
  </div>
</template>

<style lang="scss" scoped>
  .active {
    color:  var(--lar-color-danger);
  }
  .ok, .resolved {
    color:  var(--lar-color-success);
  }
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';
import Item from '@/components/blueprint/item.vue';

// import { Alarm, AlarmState } from '../interfaces';
import { Alarm } from '../interfaces';

@Component({
  components: {
    FaIcon,
    Item,
  },
})
class AlarmStateBadge extends ComponentBase {
  @Prop({ type: Object as PropType<Alarm>, required: true })
  declare public alarm: Alarm;

  // eslint-disable-next-line class-methods-use-this
  public getStateClass(state: 'active' | 'resolved' | 'ok') {
    return state;
  }

  public getAlarmStateValue(state: 'active' | 'resolved' | 'ok') {
    switch (state) {
      case 'active':
        return this.$t('Active');
      case 'resolved':
        return this.$t('Resolved');
      case 'ok':
        return this.$t('OK');
      default:
        return state;
    }
  }
}

export default toNative(AlarmStateBadge);
</script>
