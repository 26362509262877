import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-1 md:pt-2 flex items-center justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-nowrap overflow-x-scroll py-4 hide-scroll-bar"
}
const _hoisted_3 = ["onKeypress", "onClick"]
const _hoisted_4 = { class: "flex items-center justify-center" }
const _hoisted_5 = { class: "resource resource-dark" }
const _hoisted_6 = { class: "px-4 text-center mt-2 text-xs md:text-sm uppercase line-clamp-2 text-sm font-bold" }
const _hoisted_7 = {
  key: 1,
  class: "text-center m-4"
}
const _hoisted_8 = { class: "mt-2" }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = {
  key: 0,
  class: "text-right pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentTitle = _resolveComponent("ContentTitle")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_UndrawBuilding = _resolveComponent("UndrawBuilding")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ContentTitle, { class: "!mt-0" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('My Buildings')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Card, { class: "property-list px-1 pb-3" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.loading && _ctx.properties.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "inline-bloc px-2 cursor-pointer",
                    onKeypress: ($event: any) => (_ctx.$router.push({ name: 'properties.property', params: { propertyId: item.id } })),
                    onClick: ($event: any) => (_ctx.$router.push({ name: 'properties.property', params: { propertyId: item.id } }))
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_FaIcon, { icon: "fa-regular fa-building" })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1)
                  ], 40, _hoisted_3))
                }), 128))
              ]))
            : (!_ctx.loading && !_ctx.properties.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_UndrawBuilding, {
                    class: "mb-3",
                    "primary-color": "var(--lar-color-primary)",
                    height: "3rem"
                  }),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_ContentTitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('You haven’t joined any buildings yet')), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('Join a building to begin your management responsibilities.')), 1)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (index) => {
                        return _createVNode(_component_Skeleton, {
                          key: index,
                          height: "4rem",
                          class: "inline-block min-w-32 max-w-32 mx-2 rounded-md"
                        })
                      }), 64))
                    : _createCommentVNode("", true)
                ]))
        ])
      ]),
      _: 1
    }),
    (_ctx.orgRoleAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_Button, {
            label: _ctx.$t('All buildings'),
            iconPos: "right",
            icon: "pi pi-chevron-right",
            size: "small",
            text: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'properties' })))
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true)
  ]))
}