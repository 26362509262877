import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeHeader = _resolveComponent("HomeHeader")!
  const _component_HomePropertyList = _resolveComponent("HomePropertyList")!
  const _component_HomeSummary = _resolveComponent("HomeSummary")!
  const _component_HomeTechnicalSystems = _resolveComponent("HomeTechnicalSystems")!
  const _component_PageContent = _resolveComponent("PageContent")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createVNode(_component_HomeHeader),
      _createVNode(_component_PageContent, null, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_openBlock(), _createBlock(_component_HomePropertyList, {
                  key: _ctx.propertyRoleProperties.length,
                  propertyIds: _ctx.propertyRoleProperties
                }, null, 8, ["propertyIds"])),
                (!!_ctx.propertyRoleProperties.length)
                  ? (_openBlock(), _createBlock(_component_HomeSummary, {
                      key: 0,
                      allFeatures: _ctx.allFeatures
                    }, null, 8, ["allFeatures"]))
                  : _createCommentVNode("", true),
                (!!_ctx.propertyRoleProperties.length && _ctx.allFeatures.includes(_ctx.PropertyFeatureFlags.TECHNICAL_SYSTEMS))
                  ? (_openBlock(), _createBlock(_component_HomeTechnicalSystems, {
                      key: 1,
                      class: "mt-4"
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}