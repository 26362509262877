import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fadein overflow-hidden max-h-screen min-h-screen flex-shrink-0 static left-0 top-0 z-5 border-r border-surface w-auto" }
const _hoisted_2 = { class: "flex flex-col max-h-screen min-h-screen bg-primary-600 flex-shrink-0 select-none" }
const _hoisted_3 = { class: "p-3 bg-primary-600" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "overflow-y-auto overscroll-y-auto" }
const _hoisted_6 = { class: "list-none px-2 m-0" }
const _hoisted_7 = ["onKeypress", "onClick", "to"]
const _hoisted_8 = { class: "mt-auto" }
const _hoisted_9 = { class: "py-3 px-2 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Menu = _resolveComponent("Menu")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "logo",
          src: require('@/assets/larva.svg'),
          alt: "Fentrica"
        }, null, 8, _hoisted_4)
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "mb-3 mx-3 border-t border-0 border-primary-300" }, null, -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("ul", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainMenu, (menuItem) => {
            return (_openBlock(), _createElementBlock("li", {
              key: menuItem.name,
              class: "mb-2"
            }, [
              _withDirectives((_openBlock(), _createElementBlock("a", {
                onKeypress: ($event: any) => (_ctx.route(menuItem.href, menuItem.historyHref)),
                onClick: ($event: any) => (_ctx.route(menuItem.href, menuItem.historyHref)),
                to: menuItem.href,
                class: _normalizeClass([{ 'text-white': _ctx.isRouteActive(menuItem.href), 'bg-primary-400': _ctx.isRouteActive(menuItem.href) }, "max-w-24 no-underline text-center relative p-element flex flex-col items-center cursor-pointer p-2 justify-center hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors"])
              }, [
                (menuItem.badge)
                  ? (_openBlock(), _createBlock(_component_Badge, {
                      key: 0,
                      value: menuItem.badge.value,
                      severity: menuItem.badge.severity,
                      class: "badge"
                    }, null, 8, ["value", "severity"]))
                  : _createCommentVNode("", true),
                (menuItem.icon)
                  ? (_openBlock(), _createBlock(_component_FaIcon, {
                      key: 1,
                      class: _normalizeClass(["mr-1 lg:mr-0", { 'fa-shake': menuItem.badge }]),
                      icon: menuItem.icon
                    }, null, 8, ["class", "icon"]))
                  : _createCommentVNode("", true)
              ], 42, _hoisted_7)), [
                [
                  _directive_tooltip,
                  menuItem.name,
                  void 0,
                  { right: true }
                ]
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "mb-3 mx-3 border-t border-0 border-primary-300" }, null, -1)),
        _createVNode(_component_Menu, {
          ref: "userMenu",
          model: _ctx.userMenu,
          popup: true,
          style: {"width":"250px"}
        }, null, 8, ["model"]),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives((_openBlock(), _createElementBlock("a", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleUserMenu && _ctx.toggleUserMenu(...args)), ["prevent"])),
            onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleUserMenu && _ctx.toggleUserMenu(...args))),
            class: "max-w-24 no-underline text-center p-element flex flex-col items-center cursor-pointer p-2 justify-center hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors"
          }, [
            _createVNode(_component_FaIcon, {
              class: "mr-1",
              icon: "user-circle",
              style: {"margin-left":"4px"}
            })
          ], 32)), [
            [
              _directive_tooltip,
              _ctx.currentUserName,
              void 0,
              { right: true }
            ]
          ])
        ])
      ])
    ])
  ]))
}