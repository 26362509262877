<template>
  <div>
    <LarCognitoConfig
      :cognitoRegion="AuthConfig.region"
      :cognitoPoolId="AuthConfig.userPoolId"
      :cognitoClientId="AuthConfig.userPoolWebClientId"
    />
    <ProgressBar class="loadingbar" v-if="!ready" mode="indeterminate" />
    <slot v-if="ready" />
  </div>
</template>

<style lang="scss" scoped>
.loadingbar {
  transition: all .4s;
  border-radius: 0.5em;
  position: absolute;
  z-index: 1;
  width: 100vw;
  border-radius:0;
  height: .25rem;
  transition: all 0.5sec;
}

</style>

<script lang="ts">
import { LarCognitoConfig } from '@larva.io/webcomponents-cognito-login-vue';
import ProgressBar from 'primevue/progressbar';

import {
  Component, ComponentBase, toNative, Watch,
} from '@/component-base';
import AuthConfig from '@/config/auth.config';
import { Roles } from '@/store/modules/interfaces';
import { RouteLocationNormalizedLoaded } from 'vue-router';

/**
 * @component AuthGuard
 * @description A component that handles authentication and authorization for the application.
 * It manages the Cognito configuration, user authentication state, and role-based routing.
 *
 * @emits on-ready - Emitted when the component is ready and authentication is complete
 * @emits on-loading - Emitted when the component is loading or authenticating
 *
 * @example
 * <AuthGuard>
 *   <RouterView />
 * </AuthGuard>
 */
@Component({
  components: {
    LarCognitoConfig,
    ProgressBar,
  },
  emits: ['on-ready', 'on-loading'],
})
class AuthGuard extends ComponentBase {
  /*
   $refs!: {
    cognitoConfig: HTMLLarCognitoConfigElement;
  }
  */

  @Watch('$route', { deep: true })
  public routeWatcher(newValue: RouteLocationNormalizedLoaded) {
    this.routeUpdated(newValue);
  }

  public ready = false;

  /** Configuration object for AWS Cognito */
  public AuthConfig = AuthConfig;

  /**
   * Lifecycle hook called when the component is created
   * Emits the 'on-loading' event
   */
  public created() {
    this.$emit('on-loading');
  }

  /**
   * Lifecycle hook called when the component is mounted
   * Calls the load method to initialize authentication
   */
  public async mounted() {
    await this.load();
  }

  /**
   * Initializes the authentication process
   * Sets up Cognito, checks for existing tokens, and handles routing based on authentication state
   * @private
   */
  private async load() {
    this.ready = false;
    this.$emit('on-loading');
    // eslint-disable-next-line no-undef
    const Cognito = document.querySelector('lar-cognito-config') as HTMLLarCognitoConfigElement;
    await Cognito.componentOnReady();
    await Cognito.setStorage(this.AppState.rememberMe ? window.localStorage : window.sessionStorage);
    const token = await Cognito.getAccessToken();
    if (!token && this.$route.name !== 'auth.login' && !this.$route.name?.toString().startsWith('public.')) {
      await this.$router.replace({ name: 'auth.login', query: { url: window.location.pathname !== '/' ? window.location.pathname : undefined } });
    } else if (token) {
      await this.getMe();
    }
    this.ready = true;
    this.$emit('on-ready');
  }

  /**
   * Reloads the authentication state
   * @public
   */
  public async reload() {
    await this.load();
  }

  private async getMe() {
    try {
      await this.fetchMe();
      await this.changeOrg();
    } catch (err) {
      await this.error(err);
      await this.logout();
    }
  }

  public routeUpdated(route: RouteLocationNormalizedLoaded) {
    if (!route.name?.toString().startsWith('public.') && !route.name?.toString().startsWith('auth.') && !route.name?.toString().startsWith('init') && !route.name?.toString().startsWith('errors.')) {
      this.AppState.setLastRoute(route.fullPath);
    } else {
      this.AppState.setLastRoute(null);
    }
  }

  /**
   * Handles organization change and role-based routing
   * @private
   */
  private async changeOrg() {
    const { me } = this.AppState;
    if (!me) {
      await this.logout();
      return;
    }
    // If we're in PWA app mode and there's a last route stored,
    // and if the app is opened fresh (i.e., on the initial page),
    // let's redirect to the stored app route.
    // so user will resume from where he left off.
    if (this.isPWAMode() && this.AppState.lastRoute && this.isInitPage()) {
      console.info(`App is in PWA mode and  last route is stored. Redirecting to ${this.AppState.lastRoute}`);
      // do not add path to history, so user will not be able to go back
      await this.$router.replace(this.AppState.lastRoute);
      // reload app state to correct org
      await this.changeOrg();
      return;
    }
    // if we have user logged in with redirection url, we'll redirect to there
    if (this.isLoginPageWithReturnUrl()) {
      console.info(`Login page had URL to redirect. Redirecting to ${this.$route.query.url}`);
      // we must use here replace, so whole auth-guard will be reloaded
      // do not add path to history, so user will not be able to go back
      await this.$router.replace(this.$route.query.url as string);
      // reload app state to correct org
      await this.changeOrg();
      return;
    }
    // if we have no orgs, but system user, redirect to role choose page
    if (!me.orgs.length && me.hasSystemRole()) {
      await this.routeToRole();
      return;
    }
    // no orgs for current normal user
    if (!me.orgs.length) {
      await this.routeNoOrgsAvailable();
      return;
    }
    let currentOrgId: string | null = this.$route.params.orgId as string ?? this.currentOrgId;
    // Redirects the user to the "auth.choose-org" route if the current organization ID is not valid for the logged-in user
    // and the user has more than one organization.
    if ((!currentOrgId || !me.getOrg(currentOrgId)) && me.orgs.length > 1) {
      await this.routeChooseOrg();
      return;
    }
    // if we have orgId but no org for current user and we have one org, set current orgId to first org
    if ((!currentOrgId || !me.getOrg(currentOrgId)) && me.orgs.length === 1) {
      currentOrgId = me.orgs[0].id;
    }
    const org = me.getOrg(currentOrgId);
    if (!org) {
      await this.routeNoOrgsAvailable();
      return;
    }
    this.AppState.setCurrentOrg(org.plain());

    if (this.isInitialOrLoginPage()) {
      console.info('Initial or login page. Redirecting to role route based app state or url');
      await this.handleRoleBasedRedirect();
    }
  }

  private isLoginPageWithReturnUrl(): boolean {
    return this.$route.name === 'auth.login' && typeof this.$route.query.url === 'string' && this.$route.query.url.startsWith('/');
  }

  private async routeNoOrgsAvailable(): Promise<void> {
    if (this.$route.name !== 'auth.no-orgs-available') {
      await this.$router.replace({ name: 'auth.no-orgs-available' });
    }
  }

  private async routeChooseOrg(): Promise<void> {
    if (this.$route.name !== 'auth.choose-org') {
      await this.$router.replace({ name: 'auth.choose-org' });
    }
  }

  private isInitialOrLoginPage(): boolean {
    return this.$route.name === 'init' || this.$route.name === 'auth.login';
  }

  private isInitPage(): boolean {
    return this.$route.name === 'init';
  }

  // eslint-disable-next-line class-methods-use-this
  private isPWAMode(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  private async handleRoleBasedRedirect(): Promise<void> {
    const { role, me, currentOrg } = this.AppState;
    const orgRoles = {
      [Roles.ORG_ROLE]: () => !!currentOrg && me?.getOrg(currentOrg.id)?.hasOrgRole(),
      [Roles.PROPERTY_ROLE]: () => !!currentOrg && me?.getOrg(currentOrg.id)?.properties.some((p) => p.hasPropertyRole()),
      [Roles.ACCESS_GROUP]: () => !!currentOrg && me?.getOrg(currentOrg.id)?.properties.some((p) => p.hasAccessPolicyRole()),
      [Roles.EXTERNAL_PARTNER]: () => !!currentOrg && me?.getOrg(currentOrg.id)?.properties.some((p) => p.hasExternalPartnerRole()),
    };

    if (role && orgRoles[role] && orgRoles[role]()) {
      console.info(`App State chosen ${role} for org ${currentOrg?.id} exists, redirect to default role route`);
      await this.routeToRole(role);
    } else {
      console.info('Redirecting to role choose page for org as no role exists');
      await this.routeToRole();
    }
  }

  private async routeToRole(role?: Roles) {
    switch (role) {
      case Roles.ORG_ROLE:
      case Roles.PROPERTY_ROLE:
        return this.$router.replace({ name: 'home', params: { orgId: this.currentOrgId } });
      case Roles.ACCESS_GROUP:
        return this.$router.replace({ name: 'access-group-user.home', params: { orgId: this.currentOrgId } });
      case Roles.EXTERNAL_PARTNER:
        return this.$router.replace({ name: 'external-partner.home', params: { orgId: this.currentOrgId } });
      default:
        return this.$router.replace({ name: 'auth.choose-org-role', params: { orgId: this.currentOrgId } });
    }
  }
}

export default toNative(AuthGuard);
</script>
