import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "min-h-screen max-h-screen flex flex-col relative flex-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BottomMenu = _resolveComponent("BottomMenu")!

  return (_openBlock(), _createElementBlock("div", {
    key: String(_ctx.currentOrgId),
    class: "main h-screen max-h-screen min-h-screen lg:flex lg:flex-auto select-text"
  }, [
    _createVNode(_component_ProgressBar, {
      class: _normalizeClass(["loadingbar", { 'loadingbar-visible': _ctx.loading }]),
      mode: "indeterminate"
    }, null, 8, ["class"]),
    (!_ctx.loadingInternal)
      ? (_openBlock(), _createBlock(_component_SideMenu, {
          key: 0,
          menu: _ctx.menu,
          userMenu: _ctx.userMenu,
          class: "lg:flex hidden"
        }, null, 8, ["menu", "userMenu"]))
      : _createCommentVNode("", true),
    (!_ctx.loadingInternal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view),
          (!_ctx.loadingInternal)
            ? (_openBlock(), _createBlock(_component_BottomMenu, {
                key: 0,
                menu: _ctx.menu,
                userMenu: _ctx.userMenu,
                class: "lg:hidden"
              }, null, 8, ["menu", "userMenu"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}