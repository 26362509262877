import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bottom-menu flex justify-between flex-row max-w-screen min-w-screen bg-primary-600 select-none" }
const _hoisted_2 = { class: "m-0 p-0" }
const _hoisted_3 = { class: "list-none m-0 p-0 flex" }
const _hoisted_4 = ["onKeypress", "onClick"]
const _hoisted_5 = { class: "text-xs" }
const _hoisted_6 = { class: "text-sm max-w-16 whitespace-nowrap overflow-hidden overflow-ellipsis text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainMenu, (menuItem) => {
          return (_openBlock(), _createElementBlock("li", {
            key: menuItem.name,
            class: "menuitem flex justify-center flex-col items-center m-1"
          }, [
            _createElementVNode("a", {
              onKeypress: ($event: any) => (_ctx.route(menuItem.href, menuItem.historyHref)),
              onClick: ($event: any) => (_ctx.route(menuItem.href, menuItem.historyHref)),
              class: _normalizeClass([{ 'text-white': _ctx.isRouteActive(menuItem.href), 'bg-primary-400': _ctx.isRouteActive(menuItem.href) }, "overflow-hidden overflow-ellipsis relative no-underline flex-1 flex flex-col justify-center text-center p-element items-center cursor-pointer py-2 px-2 hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors"])
            }, [
              (menuItem.icon)
                ? (_openBlock(), _createBlock(_component_FaIcon, {
                    key: 0,
                    class: _normalizeClass(["text-lg mx-1", { 'fa-shake': menuItem.badge }]),
                    icon: menuItem.icon
                  }, null, 8, ["class", "icon"]))
                : _createCommentVNode("", true),
              (menuItem.badge)
                ? (_openBlock(), _createBlock(_component_Badge, {
                    key: 1,
                    value: menuItem.badge.value,
                    severity: menuItem.badge.severity,
                    class: "badge"
                  }, null, 8, ["value", "severity"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_5, _toDisplayString(menuItem.name), 1)
            ], 42, _hoisted_4)
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_Menu, {
      ref: "userMenu",
      model: _ctx.userMenu,
      popup: true,
      style: {"width":"250px"}
    }, null, 8, ["model"]),
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleUserMenu && _ctx.toggleUserMenu(...args)), ["prevent"])),
      onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleUserMenu && _ctx.toggleUserMenu(...args))),
      class: "m-1 overflow-hidden max-w-24 overflow-ellipsis no-underline text-center p-element flex flex-col items-center cursor-pointer px-2 py-2 justify-center hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors"
    }, [
      _createVNode(_component_FaIcon, {
        class: "mx-1",
        icon: "user-circle"
      }),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.currentUserName), 1)
    ], 32)
  ]))
}