/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/public',
    name: 'public',
    component: () => import('./layouts/public-layout.vue'),
    redirect: { name: 'init' },
    children: [
      {
        path: '/public/orgs/:orgId/properties/:propertyId/tickets/:ticketId/feedback',
        name: 'public.ticket.feedback',
        component: () => import('./ticket-feedback.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
