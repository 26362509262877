<template>
  <BlueprintDataView noNew id="technical_systems" :opts="opts" :title="$t('My buildings technical systems')">
    <template #empty>
      <UndrawEmpty class="mb-3" primary-color='var(--lar-color-primary)' height="9rem" />
      <div class="mt-2">
        {{$t('There are no technical systems linked in any building.')}}
      </div>
    </template>
    <template #filter-type="{ filter }">
      <FilterChips v-model="filter.value" :options="typesOptions" />
    </template>
    <template #filter-propertyId="{ filter }">
      <FilterBlueprintDropdown
        :opts="propertyOpts"
        v-model="filter.value"
        :applyFilterLabel="$t('Select Building')"
      />
    </template>
    <template #list="{ data }">
      <TechnicalSystemListItem @click="openTechnicalSystem(data)" :technicalSystem="data" />
    </template>
  </BlueprintDataView>
</template>

<script lang="ts">
import UndrawEmpty from 'vue-undraw/UndrawEmpty';

import { Component, ComponentBase, toNative } from '@/component-base';
import BlueprintDataView from '@/components/blueprint/blueprint-data-view.vue';
import { FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import FilterBlueprintDropdown from '@/components/filter-inputs/filter-blueprint-dropdown.vue';
import FilterChips from '@/components/filter-inputs/filter-chips.vue';
import { BlueprintOptions } from '@/components/helpers/blueprint';

import TechnicalSystemListItem from '../../property-role/modules/measurements/components/technical-system-list-item.vue';
import { TechnicalSystem, TechnicalSystemsTypesNames } from '../../property-role/modules/measurements/interfaces';

@Component({
  components: {
    UndrawEmpty,
    BlueprintDataView,
    TechnicalSystemListItem,
    FilterBlueprintDropdown,
    FilterChips,
  },
})
class HomeTechnicalSystems extends ComponentBase {
  public get typesOptions() {
    return TechnicalSystemsTypesNames.map((t) => ({ ...t, text: this.$t(t.text) }));
  }

  public get propertyOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties', // my properties?
      params: { orgId: String(this.currentOrgId) },
      fields: [
        { field: 'id' },
        { field: 'name', searchable: true },
      ],
    };
  }

  public get opts(): BlueprintOptions<TechnicalSystem> {
    return {
      http: this.measurements,
      apiRoutePath: 'orgs/:orgId/technical-systems',
      params: {
        orgId: this.currentOrgId || '',
      },
      fields: [
        {
          field: 'updatedAt', header: this.$t('Newest'), noNew: true, noEdit: true, noList: true, sortable: true,
        },
        {
          field: 'id', noEdit: true, noList: true, noNew: true,
        },
        {
          field: 'name', header: this.$t('Name'), sortable: true, required: true, searchable: true,
        },
        {
          field: 'label', header: this.$t('Label'), sortable: true, required: false, searchable: true,
        },
        {
          field: 'description', header: this.$t('Description'), sortable: true, required: false, type: 'textarea',
        },
        {
          field: 'type',
          header: this.$t('Type'),
          sortable: true,
          filterable: true,
          filterMatchMode: FilterMatchMode.IN,
        },
        {
          field: 'propertyId', header: this.$t('Building'), noList: true, filterable: true,
        },
      ],
    };
  }

  public async openTechnicalSystem(item: TechnicalSystem) {
    await this.$router.push({
      name: 'home.technical-systems.technical-system',
      params: { id: item.id, propertyId: item.propertyId, orgId: item.orgId },
    });
  }
}

export default toNative(HomeTechnicalSystems);
</script>
