<template>
  <div class="avatar relative mr-3">
    <BlueprintItem v-if="notification.log.associations.accessGroupUserId" :id="notification.log.associations.accessGroupUserId" :opts="accessGroupUserOpts">
      <template #value="{ item }">
        <GravatarImg :firstname="item.firstname" :lastname="item.lastname" :email="item.email" />
      </template>
    </BlueprintItem>
    <BlueprintItem v-else-if="notification.log.associations.accessGroupLicensePlateId" :id="notification.log.associations.accessGroupLicensePlateId" :opts="accessGroupPlateAssociationOpts">
      <template #value="{ item }">
        <GravatarImg :firstname="item.firstname" :lastname="item.lastname" :email="item.email" />
      </template>
    </BlueprintItem>
    <BlueprintItem v-else-if="notification.log.associations.accessGroupCardId" :id="notification.log.associations.accessGroupCardId" :opts="accessGroupPlateAssociationOpts">
      <template #value="{ item }">
        <GravatarImg :firstname="item.firstname" :lastname="item.lastname" :email="item.email" />
      </template>
    </BlueprintItem>
    <BlueprintItem v-else-if="notification.log.associations.orgRoleId" :id="notification.log.associations.orgRoleId" :opts="usersOpts">
      <template #value="{ item }">
        <GravatarImg :firstname="item.firstname" :lastname="item.lastname" :email="item.email" />
      </template>
    </BlueprintItem>
    <BlueprintItem v-else-if="notification.log.associations.propertyRoleId" :id="notification.log.associations.propertyRoleId" :opts="propertyRoleOpts">
      <template #value="{ item }">
        <GravatarImg :firstname="item.firstname" :lastname="item.lastname" :email="item.email" />
      </template>
    </BlueprintItem>
    <BlueprintItem v-else-if="notification.log.associations.externalPartnerId" :id="notification.log.associations.externalPartnerId" :opts="externalPartnersOpts">
      <template #value="{ item }">
        <GravatarImg :firstname="item.firstname" :lastname="item.lastname" :email="item.email" />
      </template>
    </BlueprintItem>
    <BlueprintItem
      v-else-if="notification.log.associations.commentId && (notification.log.associations.taskId || notification.log.associations.ticketId)"
      :id="notification.log.associations.commentId"
      :opts="commentsOpts(notification.log.associations.ticketId, notification.log.associations.taskId)"
    >
      <template #value="{ item }">
        <GravatarImg v-if="item.externalData && (item.externalData.email || item.externalData.from)" :email="item.externalData.email" :firstname="item.externalData.from?.split(' ')[0]" :lastname="item.externalData.from?.split(' ')[1]" />
        <Avatar v-else shape="circle" class="p-4">
          <FaIcon size="xl" icon="fa-regular fa-building" />
        </Avatar>
      </template>
    </BlueprintItem>
    <BlueprintItem v-else-if="notification.log.associations.taskId" :id="notification.log.associations.taskId" :opts="tasksOpts">
      <template #value="{ item }">
        <GravatarImg v-if="item.externalData && (item.externalData.email || item.externalData.from)" :email="item.externalData.email" :firstname="item.externalData.from?.split(' ')[0]" :lastname="item.externalData.from?.split(' ')[1]" />
        <Avatar v-else shape="circle" class="p-4">
          <FaIcon size="xl" icon="fa-regular fa-building" />
        </Avatar>
      </template>
    </BlueprintItem>
    <BlueprintItem v-else-if="notification.log.associations.ticketId" :id="notification.log.associations.ticketId" :opts="ticketsOpts">
      <template #value="{ item }">
        <GravatarImg v-if="item.externalData && (item.externalData.email || item.externalData.from)" :email="item.externalData.email" :firstname="item.externalData.from?.split(' ')[0]" :lastname="item.externalData.from?.split(' ')[1]" />
        <Avatar v-else shape="circle" class="p-4">
          <FaIcon size="xl" icon="fa-regular fa-building" />
        </Avatar>
      </template>
    </BlueprintItem>
    <Avatar v-else shape="circle" class="p-4">
      <FaIcon size="xl" icon="fa-regular fa-building" />
    </Avatar>
    <!--- icon --->
    <div class="resource-icon" :class="resourceClass" v-if="resourceIcon">
      <FaIcon :icon="resourceIcon" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  min-width: 3.5rem;
  width: 3.5rem;
  min-height: 4rem;
}
.resource-icon {
  position: absolute;
  top: 2.2rem;
  right: 0;
  background-color: var(--lar-color-warning);
  color: var(--lar-color-warning-contrast);
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  font-size: .95rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-avatar {
  min-width: 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
  color: var(--lar-text-color-step-200);
}

.resource-dark {
  background-color: var(--lar-color-dark);
  color: var(--lar-color-dark-contrast);
}
.resource-medium {
  background-color: var(--lar-color-medium);
  color: var(--lar-color-medium-contrast);
}
.resource-success {
  background-color: var(--lar-color-success);
  color: var(--lar-color-success-contrast);
}
.resource-info {
  background-color: var(--lar-color-tertiary);
  color: var(--lar-color-tertiary-contrast);
}
.resource-warning {
  background-color: var(--lar-color-warning);
  color: var(--lar-color-warning-contrast);
}
.resource-danger {
  background-color: var(--lar-color-danger);
  color: var(--lar-color-danger-contrast);
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Avatar from 'primevue/avatar';

import {
  Component, ComponentBase, Prop, PropType, toNative,
} from '@/component-base';
import BlueprintItem from '@/components/blueprint/blueprint-item.vue';
import GravatarImg from '@/components/formatters/gravatar-img.vue';
import { BlueprintOptions } from '@/components/helpers/blueprint';

import { PushNotification } from '../interfaces';

@Component({
  components: {
    BlueprintItem,
    GravatarImg,
    Avatar,
    FaIcon,
  },
})
class NotificationGravatar extends ComponentBase {
  @Prop({ type: Object as PropType<BlueprintOptions>, required: true })
  declare public notification: PushNotification;

  public get hasResource() {
    return this.notification.log.associations.alarmId || this.notification.log.associations.ticketId || this.notification.log.associations.ticketId;
  }

  public get resourceClass() {
    if (this.notification.log.associations.alarmId) {
      return 'resource-danger';
    }
    if (this.notification.log.associations.taskId) {
      return 'resource-warning';
    }
    if (this.notification.log.associations.ticketId) {
      return 'resource-info';
    }
    if (this.notification.log.associations.unitId) {
      return 'resource-medium';
    }
    return 'resource-dark';
  }

  public get resourceIcon() {
    if (this.notification.log.associations.alarmId) {
      return 'exclamation-triangle';
    }
    if (this.notification.log.associations.taskId) {
      return 'clipboard-check';
    }
    if (this.notification.log.associations.ticketId) {
      return 'fa-person-circle-exclamation';
    }
    if (this.notification.log.associations.unitId) {
      return 'door-open';
    }
    return null;
  }

  public get usersOpts(): BlueprintOptions<{ email: string }> {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/org-roles',
      params: { orgId: this.notification.log?.orgId },
    };
  }

  public get propertyRoleOpts(): BlueprintOptions<{ email: string }> {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/property-roles',
      params: { orgId: this.notification.log?.orgId, propertyId: this.notification.log?.propertyId },
    };
  }

  public get accessGroupUserOpts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/access-group-users',
      params: { orgId: this.notification.log?.orgId ?? '', propertyId: this.notification.log?.propertyId ?? '' },
    };
  }

  public get externalPartnersOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/external-partners',
      params: { orgId: this.notification.log?.orgId ?? '', propertyId: this.notification.log?.propertyId ?? '' },
    };
  }

  public get accessGroupPlateAssociationOpts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/access-group-license-plates',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public get accessGroupCardAssociationOpts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/access-group-cards',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public commentsOpts(ticketId?: string|null, taskId?: string|null): BlueprintOptions {
    if (!ticketId && !taskId) {
      throw new Error('Invalid comment opts input');
    }
    const entity = ticketId ? 'tickets' : 'tasks';
    return {
      http: this.beetle,
      apiRoutePath: `orgs/:orgId/properties/:propertyId/${entity}/:itemId/comments`,
      params: {
        orgId: this.notification.log?.orgId || '',
        propertyId: this.notification.log?.propertyId || '',
        itemId: String(ticketId ?? taskId),
      },
    };
  }

  public get ticketsOpts(): BlueprintOptions {
    return {
      http: this.beetle,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/tickets',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }

  public get tasksOpts(): BlueprintOptions {
    return {
      http: this.beetle,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/tasks',
      params: { orgId: this.notification.log?.orgId || '', propertyId: this.notification.log?.propertyId || '' },
    };
  }
}

export default toNative(NotificationGravatar);
</script>
