<template>
  <router-view />
</template>

<script lang="ts">
import { Component, ComponentBase, toNative } from '@/component-base';

@Component({
  components: {
  },
})
class ReportsLayout extends ComponentBase {

}

export default toNative(ReportsLayout);
</script>
