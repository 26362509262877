import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Item = _resolveComponent("Item")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.getStateClass(_ctx.alarm.state)], "whitespace-nowrap overflow-ellipsis overflow-hidden"])
  }, [
    _createVNode(_component_Item, {
      faIcon: _ctx.alarm.state === 'active' ? 'circle' : 'check',
      label: _ctx.getAlarmStateValue(_ctx.alarm.state)
    }, null, 8, ["faIcon", "label"])
  ], 2))
}