import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = { class: "flex items-center ml-2" }
const _hoisted_3 = {
  key: 0,
  class: "uppercase text-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_PageToolbar = _resolveComponent("PageToolbar")!

  return (_openBlock(), _createBlock(_component_PageToolbar, null, {
    start: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.$slots.start)
          ? _renderSlot(_ctx.$slots, "start", { key: 0 })
          : _createCommentVNode("", true)
      ])
    ]),
    end: _withCtx(() => [
      (_ctx.$slots.end)
        ? _renderSlot(_ctx.$slots, "end", { key: 0 })
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.currentOrg && !_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, [
              _createVNode(_component_FaIcon, {
                icon: "briefcase",
                class: "mr-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.currentOrg.name), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.default)
          ? (_openBlock(), _createBlock(_component_PageTitle, { key: 1 }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}