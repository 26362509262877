import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/alarms',
    name: 'properties.property.measurements.alarms',
    redirect: { name: 'properties.property.measurements.alarms.active' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/alarms/active',
    name: 'properties.property.measurements.alarms.active',
    component: () => import('./alarms-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/alarms/clear',
    name: 'properties.property.measurements.alarms.toclear',
    component: () => import('./alarms-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/alarms/cleared',
    name: 'properties.property.measurements.alarms.cleared',
    component: () => import('./alarms-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/alarms/ack',
    name: 'properties.property.measurements.alarms.ack',
    component: () => import('./alarms-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/alarms/:id',
    name: 'properties.property.measurements.alarms.alarm',
    component: () => import('./alarms-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/technical-systems',
    name: 'properties.property.measurements.technical-systems',
    component: () => import('./technical-systems-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/technical-systems/:id',
    name: 'properties.property.measurements.technical-systems.technical-system',
    component: () => import('./technical-systems-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/smart-metering',
    name: 'properties.property.measurements.smart-metering',
    redirect: { name: 'properties.property.measurements.smart-metering.overview' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/smart-metering/overview',
    name: 'properties.property.measurements.smart-metering.overview',
    component: () => import('./smart-metering-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/smart-metering/consumption',
    name: 'properties.property.measurements.smart-metering.consumption',
    component: () => import('./smart-metering-consumption-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/smart-metering/data',
    name: 'properties.property.measurements.smart-metering.data',
    component: () => import('./smart-metering-data-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/sensors',
    name: 'properties.property.measurements.sensors',
    redirect: { name: 'properties.property.measurements.sensors.smart-alarms' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/sensors/smart-alarms',
    name: 'properties.property.measurements.sensors.smart-alarms',
    component: () => import('./sensors-smart-alarms.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/sensors/units',
    name: 'properties.property.measurements.sensors.units',
    component: () => import('./sensors-units.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/schedules',
    name: 'properties.property.orgs.inventory.schedules',
    component: () => import('./schedules-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/schedules/:id',
    name: 'properties.property.orgs.inventory.schedules.schedule',
    component: () => import('./schedules-item.vue'),
  },
] as RouteRecordRaw[];
