import { Log } from '../mantis/interfaces';

/* eslint-disable no-shadow */
export enum TicketState {
  NEW = 'new',
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  ON_HOLD = 'on_hold',
  RESOLVED = 'resolved',
  CLOSED = 'closed'
}

export interface Ticket {
  id: string;
  orgId: string;
  propertyId: string;
  propertyRoleId?: string | null;
  accessGroupUserId?: string | null;
  serviceConnectionId?: string | null;
  unitId?: string | null;
  number?: number;
  feedback?: number;
  state?: TicketState;
  title: string;
  description?: string | null;
  assignedToId?: string | null;
  supervisedById?: string | null;
  externalId?: string | null;
  externalData?: Record<string, any> | null;
  createdAt: string;
  updatedAt: string;
  resolvedAt?: string;
}

export interface TicketTask {
  id: string;
  ticketId: string;
  taskId: string;
}

export enum TaskState {
  NEW = 'new',
  CONFIRMED = 'confirmed',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  CANCELED = 'canceled',
  CLOSED = 'closed',
}

export enum TaskType {
  INSPECTION = 'inspection',
  TESTING = 'testing',
  PERIODIC = 'periodic',
  CONDITIONAL = 'conditional',
  CORRECTIVE = 'corrective',
  REPAIR = 'repair',
  EMERGENCY = 'emergency',
  WARRANTY = 'warranty'
}

export const TaskTypeTexts = [
  { name: 'Inspection', value: TaskType.INSPECTION },
  { name: 'Testing', value: TaskType.TESTING },
  { name: 'Periodic Maintenance', value: TaskType.PERIODIC },
  { name: 'Conditional Maintenance', value: TaskType.CONDITIONAL },
  { name: 'Corrective Maintenance', value: TaskType.CORRECTIVE },
  { name: 'Repair', value: TaskType.REPAIR },
  { name: 'Emergency', value: TaskType.EMERGENCY },
  { name: 'Warranty', value: TaskType.WARRANTY },
];

export const taskStateTexts = [
  { name: 'New', value: TaskState.NEW },
  { name: 'Confirmed', value: TaskState.CONFIRMED },
  { name: 'In Progress', value: TaskState.IN_PROGRESS },
  { name: 'To Review', value: TaskState.DONE },
  { name: 'Canceled', value: TaskState.CANCELED },
  { name: 'Closed', value: TaskState.CLOSED },
];

export const ticketStatesTexts = [
  { name: 'New', value: TicketState.NEW },
  { name: 'Open', value: TicketState.OPEN },
  { name: 'In Progress', value: TicketState.IN_PROGRESS },
  { name: 'On Hold', value: TicketState.ON_HOLD },
  { name: 'To Review', value: TicketState.RESOLVED },
  { name: 'Closed', value: TicketState.CLOSED },
];

export interface Task {
  id: string;
  orgId: string;
  number: number,
  name: string;
  deadline: string;
  description: string;
  resolutionSummary?: string,
  propertyRoleId: string;
  propertyId: string;
  serviceConnectionId?: string | null;
  assignedToId?: string | null;
  supervisedById?: string | null;
  state: TaskState;
  type: TaskType;
  createdAt: string;
  updatedAt: string;
  doneAt?: string;
  externalData?: Record<string, any> | null;
  recurringTaskId?: string;
}

export interface Comment {
  id: string;
  orgId: string;
  propertyId: string;
  taskId?: string | null;
  ticketId: string | null;
  propertyRoleId?: string | null;
  externalPartnerId?: string | null;
  accessGroupUserId?: string | null;
  externalData?: Record<string, any> | null;
  content: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum ChangeLogEntity {
  TICKET = 'ticket',
  TASK = 'task',
  COMMENT = 'comment',
}

export type ChangeFieldType = keyof Ticket | keyof Comment | keyof Task;

export type ChangeLog = Log & {
  data: {
    changed?: ChangeFieldType[],
    entity: ChangeLogEntity
  }
};

export interface RecurringTask {
  id: string;
  orgId: string;
  propertyId: string;
  rule: string;
  name: string;
  description?: string | null;
  assignedToId?: string | null;
  supervisedById?: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface SmartDocsFile {
  id: string;
  orgId: string;
  propertyId: string;
  S3Key: string;
  S3Prefix?: string | null;
  extension?: string | null;
  size?: number | null;
  createdAt: string;
  updatedAt: string;
}
