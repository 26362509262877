import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageGripMenu = _resolveComponent("PageGripMenu")!
  const _component_PageActionBar = _resolveComponent("PageActionBar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_UndrawMessageSent = _resolveComponent("UndrawMessageSent")!
  const _component_ContentTitle = _resolveComponent("ContentTitle")!
  const _component_NotificationListItem = _resolveComponent("NotificationListItem")!
  const _component_BlueprintDataView = _resolveComponent("BlueprintDataView")!
  const _component_PageContent = _resolveComponent("PageContent")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createVNode(_component_PageActionBar, null, {
        default: _withCtx(() => [
          _createVNode(_component_PageGripMenu, { menu: _ctx.gripMenu }, null, 8, ["menu"])
        ]),
        _: 1
      }),
      _createVNode(_component_PageContent, null, {
        default: _withCtx(() => [
          _createVNode(_component_BlueprintDataView, {
            class: "mt-2",
            title: _ctx.$t('My Notifications'),
            sortBy: "createdAt",
            ref: "blueprint",
            noNew: "",
            filter: _ctx.filter,
            id: "notifications",
            opts: _ctx.opts
          }, {
            new: _withCtx(({ length }) => [
              (length && !!_ctx.unreadNotifications)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    onClick: _ctx.markAllAsRead,
                    size: "small",
                    icon: "pi pi-check",
                    text: "",
                    label: _ctx.$t('Mark all as read')
                  }, null, 8, ["onClick", "label"]))
                : _createCommentVNode("", true)
            ]),
            empty: _withCtx(() => [
              _createVNode(_component_UndrawMessageSent, {
                class: "mb-3",
                "primary-color": "var(--lar-color-primary)",
                height: "9rem"
              }),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ContentTitle, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$route.name === 'notifications.new' ? _ctx.$t('No unread notifications') : _ctx.$t('No notifications')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            list: _withCtx(({ data }) => [
              _createVNode(_component_NotificationListItem, {
                onClick: ($event: any) => (_ctx.openNotification(data)),
                notification: data
              }, null, 8, ["onClick", "notification"])
            ]),
            _: 1
          }, 8, ["title", "filter", "opts"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}