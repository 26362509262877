<template>
  <div class="flex items-center cursor-pointer hover:bg-surface-50 py-3 px-2 md:px-3">
    <div class="flex items-center justify-center" v-if="faIcon">
      <div class="resource rounded-full aspect-square p-1 md:p-2 text-sm md:text-lg !leading-none flex justify-center items-center" :class="`resource-${variant}`">
        <FaIcon fixedWidth :icon="faIcon" />
      </div>
    </div>
    <div class="overflow-hidden ml-1 md:ml-2 flex-grow flex flex-col">
      <div class="text-xs sm:text-sm font-bold whitespace-nowrap uppercase overflow-hidden overflow-ellipsis" v-if="title">{{title}}</div>
    </div>
    <div class="flex items-center justify-right" v-if="faIcon">
      <Badge severity="secondary" class="mr-1 md:mr-2" :value="value" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.resource-dark {
  background-color: var(--lar-color-dark);
  color: var(--lar-color-dark-contrast);
}
.resource-medium {
  background-color: var(--lar-color-medium);
  color: var(--lar-color-medium-contrast);
}
.resource-success {
  background-color: var(--lar-color-success);
  color: var(--lar-color-success-contrast);
}
.resource-info {
  background-color: var(--lar-color-tertiary);
  color: var(--lar-color-tertiary-contrast);
}
.resource-warning {
  background-color: var(--lar-color-warning);
  color: var(--lar-color-warning-contrast);
}
.resource-danger {
  background-color: var(--lar-color-danger);
  color: var(--lar-color-danger-contrast);
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Badge from 'primevue/badge';

import {
  Component, ComponentBase, Prop, toNative,
} from '@/component-base';

@Component({
  components: {
    FaIcon,
    Badge,
  },
})
class HomeSummaryCardItem extends ComponentBase {
  @Prop({ type: String, required: false })
  declare public faIcon?: string;

  @Prop({ type: String, required: false })
  declare public title?: string;

  @Prop({ type: String, required: false, default: 'dark' })
  declare public variant: string;

  @Prop({ type: Number, required: false })
  declare public value: number;
}

export default toNative(HomeSummaryCardItem);
</script>
