<template>
  <div :class="[priorityClass(alarm.priority)]" class="whitespace-nowrap overflow-ellipsis overflow-hidden">
    <FaIcon icon="triangle-exclamation" />
    {{ priorityLabel(alarm.priority) }}
  </div>
</template>

<style lang="scss" scoped>
  .medium {
    color:  var(--lar-color-warning);
  }
  .high {
    color:  var(--lar-color-danger);
  }
  .low {
    color:  var(--lar-color-tertiary);
  }
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

import {
  Component, ComponentBase, Prop, PropType, toNative,
} from '@/component-base';

// import { Alarm, AlarmState } from '../interfaces';
import { Alarm } from '../interfaces';

@Component({
  components: {
    FaIcon,
  },
})
class AlarmPriorityBadge extends ComponentBase {
  @Prop({ type: Object as PropType<Alarm>, required: true })
  declare public alarm: Alarm;

  // eslint-disable-next-line class-methods-use-this
  public priorityClass(priority: number) {
    switch (priority) {
      case 0:
        return 'low';
      case 1:
        return 'medium';
      case 2:
        return 'high';
      default:
        return priority;
    }
  }

  public priorityLabel(priority: number) {
    switch (priority) {
      case 0:
        return this.$t('Low');
      case 1:
        return this.$t('Medium');
      case 2:
        return this.$t('Critical');
      default:
        return priority;
    }
  }
}
export default toNative(AlarmPriorityBadge);
</script>
