<template>
  <div class="max-h-screen h-screen min-h-screen max-w-screen min-w-screen flex flex-col">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase, toNative } from '@/component-base';

@Component({})
class AuthLayout extends ComponentBase {

}
export default toNative(AuthLayout);
</script>
