import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ml-1 overflow-hidden" }
const _hoisted_2 = { class: "m-0 p-0 line-clamp-1 text-surface-800" }
const _hoisted_3 = {
  key: 1,
  class: "whitespace-nowrap inline-block"
}
const _hoisted_4 = { class: "mt-1 p-0 m-0 block whitespace-nowrap overflow-ellipsis overflow-hidden" }
const _hoisted_5 = { class: "m-0 p-0 line-clamp-2 text-surface-800" }
const _hoisted_6 = { class: "m-0 pt-1 line-clamp-2 text-surface-800" }
const _hoisted_7 = { class: "capitalize" }
const _hoisted_8 = {
  key: 0,
  class: "whitespace-nowrap inline-block"
}
const _hoisted_9 = {
  key: 0,
  class: "flex h-full mr-1 items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueprintItem = _resolveComponent("BlueprintItem")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_DataCard = _resolveComponent("DataCard")!

  return (_openBlock(), _createBlock(_component_DataCard, { faIcon: _ctx.icon }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          (_ctx.technicalSystem.propertyId)
            ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                key: 0,
                class: "inline-block",
                id: _ctx.technicalSystem.propertyId,
                faIcon: "building",
                opts: _ctx.propertiesOpts
              }, null, 8, ["id", "opts"]))
            : _createCommentVNode("", true),
          (_ctx.technicalSystem.unitId)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "• "))
            : _createCommentVNode("", true),
          (_ctx.technicalSystem.propertyId && _ctx.technicalSystem.unitId)
            ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                key: 2,
                class: "inline-block",
                id: _ctx.technicalSystem.unitId,
                opts: _ctx.unitOpts(_ctx.technicalSystem.propertyId)
              }, null, 8, ["id", "opts"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.technicalSystem.name), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.technicalSystem.description), 1),
        _createElementVNode("p", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(_ctx.technicalSystem.type)), 1),
          (_ctx.technicalSystem.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, " • "))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.technicalSystem.label), 1)
        ])
      ])
    ]),
    end: _withCtx(() => [
      (_ctx.alarmCount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_FaIcon, {
              icon: "warning",
              class: "text-3xl warning"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["faIcon"]))
}