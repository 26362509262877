/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

import AuthLayout from './layouts/auth.vue';

export default [
  {
    path: '/auth',
    redirect: '/auth/login',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: (): Promise<any> => import('./pages/login.vue'),
      },
      {
        path: 'no-orgs-available',
        name: 'auth.no-orgs-available',
        component: (): Promise<any> => import('./pages/no-orgs-available.vue'),
      },
      {
        path: 'choose-org',
        name: 'auth.choose-org',
        component: (): Promise<any> => import('./pages/choose-org.vue'),
      },
      {
        path: '/orgs/:orgId/role',
        name: 'auth.choose-org-role',
        component: (): Promise<any> => import('./pages/choose-org-role.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
