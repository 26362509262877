import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex-grow flex flex-col justify-center items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationGravatar = _resolveComponent("NotificationGravatar")!
  const _component_NotificationContent = _resolveComponent("NotificationContent")!
  const _component_FormatterDateTimeAgo = _resolveComponent("FormatterDateTimeAgo")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_DataCard = _resolveComponent("DataCard")!

  return (_openBlock(), _createBlock(_component_DataCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NotificationGravatar, { notification: _ctx.notification }, null, 8, ["notification"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_NotificationContent, { notification: _ctx.notification }, null, 8, ["notification"]),
          _createVNode(_component_FormatterDateTimeAgo, {
            class: _normalizeClass([{ 'text-surface-600': _ctx.notification.readAt, 'text-primary': !_ctx.notification.readAt }, "text-sm text-center"]),
            value: _ctx.notification.createdAt
          }, null, 8, ["class", "value"])
        ]),
        (_openBlock(), _createElementBlock("div", {
          key: `${_ctx.notification.id}-${_ctx.notification.readAt}`,
          class: "flex justify-end items-center"
        }, [
          (!_ctx.notification.readAt)
            ? (_openBlock(), _createBlock(_component_FaIcon, {
                key: 0,
                size: "xs",
                class: _normalizeClass(["mr-1", { 'text-danger': !_ctx.notification.readAt }]),
                fixedWidth: "",
                icon: !_ctx.notification.readAt ? 'circle' : 'check'
              }, null, 8, ["class", "icon"]))
            : _createCommentVNode("", true)
        ]))
      ])
    ]),
    _: 1
  }))
}