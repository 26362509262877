<template>
  <DataCard>
    <template #content>
      <div class="flex">
        <NotificationGravatar :notification="notification" />
        <div class="flex-grow flex flex-col justify-center items-start">
          <NotificationContent :notification="notification" />
          <FormatterDateTimeAgo :class="{ 'text-surface-600': notification.readAt, 'text-primary': !notification.readAt }" class="text-sm text-center" :value="notification.createdAt" />
        </div>
        <div :key="`${notification.id}-${notification.readAt}`" class="flex justify-end items-center">
          <FaIcon v-if="!notification.readAt" size="xs" class="mr-1" fixedWidth :class="{ 'text-danger': !notification.readAt }" :icon="!notification.readAt ? 'circle' : 'check'" />
        </div>
      </div>
    </template>
  </DataCard>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Button from 'primevue/button';

import {
  Component, ComponentBase, Prop, PropType, toNative,
} from '@/component-base';
import BlueprintItem from '@/components/blueprint/blueprint-item.vue';
import DataCard from '@/components/data-card/data-card.vue';
import FormatterDateTimeAgo from '@/components/formatters/formatter-date-time-ago.vue';

import { PushNotification } from '../interfaces';
import NotificationContent from './notification-content.vue';
import NotificationGravatar from './notification-gravatar.vue';

@Component({
  components: {
    Button,
    DataCard,
    FaIcon,
    BlueprintItem,
    FormatterDateTimeAgo,
    NotificationGravatar,
    NotificationContent,
  },
})
class NotificationListItem extends ComponentBase {
  @Prop({ type: Object as PropType<PushNotification>, required: true })
  declare public notification: PushNotification;
}

export default toNative(NotificationListItem);
</script>
