<template>
  <div @click="registerPushNotifications" @keyup="registerPushNotifications">
    <slot />
  </div>
</template>

<script lang="ts">
import { BAQuery } from '@larva.io/blueapi-query';

import {
  Component, ComponentBase, toNative, Watch,
} from '@/component-base';
import { BlueprintFilter, FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import BARequestHelper from '@/components/helpers/blueapi';
import { BlueprintOptions } from '@/components/helpers/blueprint';
import firebaseConfig from '@/config/firebase.config';
import { Unsubscribe } from '@/plugins/firebase';

/**
 * PushNotificationsGuard Component
 *
 * This component handles the registration and management of push notifications.
 * It provides a wrapper for push notification permissions and handles the logic
 * for registering, unregistering tokens, and polling for notifications.
 *
 * ios requires user engagement (event) if asking push notify permissions -
 * thats why we're using wrapper and click method for permissions prompt
 */
@Component({})
class PushNotificationsGuard extends ComponentBase {
  private onMessageHandlerUnsubscribe?: Unsubscribe;

  @Watch('currentOrgId')
  public async currentOrgIdWatcher() {
    this.AppState.setPushNotifyRegistered(false); // register token under each selected orgId
    await this.pollNotificationsCount();
  }

  /** Flag to track if the register button has been clicked */
  private registerClicked = false;

  /** Flag to prevent multiple simultaneous polling operations */
  private pollRunning = false;

  private timeout: NodeJS.Timeout | null = null;

  /**
   * Checks if push notifications are supported in the current environment
   * @returns {boolean} True if push notifications are supported, false otherwise
   */
  // eslint-disable-next-line class-methods-use-this
  public get isSupported() {
    return !!('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window);
  }

  /**
   * Checks if push notification registration is available
   * @returns {boolean} True if registration is available, false otherwise
   */
  public get isRegistrationAvailable() {
    return !!this.me && !!this.currentOrgId && !process.env.VUE_APP_ENV?.startsWith('dev') && !this.registerClicked && this.notDenied && !this.AppState.pushNotifyRegistered;
  }

  /**
   * Checks if push notifications are not denied by the user
   * @returns {boolean} True if not denied, false otherwise
   */
  // eslint-disable-next-line class-methods-use-this
  public get notDenied() {
    return Notification?.permission !== 'denied';
  }

  /**
   * Lifecycle hook called after the component is mounted
   */
  public async mounted() {
    this.setupPushNotify();
    await this.pollNotificationsCount();
  }

  /**
   * Lifecycle hook called before the component is unmounted
   */
  public unmounted() {
    if (this.onMessageHandlerUnsubscribe) {
      this.onMessageHandlerUnsubscribe();
      this.onMessageHandlerUnsubscribe = undefined;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.eventBus.off('logout', this.unregisterPushNotify);
    this.eventBus.off('poll-notifications', this.pollNotificationsCount);
  }

  /**
   * Polls for the count of unread notifications
   */
  public async pollNotificationsCount() {
    try {
      if (this.pollRunning) {
        console.warn('Poll notifications is running. Skip poll.');
        return;
      }
      if (!this.me || !this.currentOrgId) {
        console.warn('Poll notifications is missing current user or current org. Skip notifications check.');
        this.AppState.setUnreadNotifications(0);
        return;
      }
      if (!this.currentOrgId || !this.me?.getOrg(this.currentOrgId)?.orgRoles()?.length) {
        console.warn('Current user does not have selected orgRole - Skip notifications check');
        this.AppState.setUnreadNotifications(0);
        return;
      }
      this.pollRunning = true;
      const filter = BARequestHelper.generateFilter(this.notificationsFilters);
      const params = BAQuery.createQueryObject({ filter });
      const url = `${BARequestHelper.generateRoutePath(this.notificationsOpts.apiRoutePath, this.notificationsOpts.params)}/count`;
      const { data } = await this.mantis.get<{ count: number }>(url, { params, skipAuthRedirect: true, skipNotFoundRedirect: true });
      const { count } = data;
      this.AppState.setUnreadNotifications(count);
    } catch (err) {
      this.AppState.setUnreadNotifications(0);
      console.error('Error getting Notifications count', err);
    } finally {
      // wait for 10 sec at least, if we have many notifications then we'll wait for some time for next poll
      this.timeout = setTimeout(() => {
        this.pollRunning = false;
        this.timeout = null;
      }, 10000);
    }
  }

  /**
   * Filters for unread notifications
   */
  // eslint-disable-next-line class-methods-use-this
  private get notificationsFilters(): BlueprintFilter {
    return {
      readAt: {
        value: null,
        matchMode: FilterMatchMode.EQUALS,
      },
    };
  }

  /**
   * Options for the notifications API
   */
  private get notificationsOpts(): BlueprintOptions {
    return {
      http: this.mantis,
      apiRoutePath: 'orgs/:orgId/push-notifications',
      params: { orgId: String(this.currentOrgId) },
    };
  }

  /**
   * Sets up push notifications
   */
  private setupPushNotify() {
    if (!this.me) {
      console.warn('User not logged in for push notifications registration');
    }
    if (process.env.VUE_APP_ENV?.startsWith('dev')) {
      console.warn(`ServiceWorkers and PushNotifications are disabled in development mode. 
        In the development mode the noopServiceWorker.js is used. 
        This service worker file is effectively a 'no-op' that will reset any previous service worker registered for the same host:port combination.
        For more details, see https://cli.vuejs.org/core-plugins/pwa.html
      `);
    }
    if (!this.isSupported) {
      console.warn('PushNotifications not supported on this browser environment');
    }
    if (this.AppState.pushNotifyRegistered) {
      console.info('PushNotifications already registered.');
    }
    if (Notification?.permission === 'denied') {
      console.warn('PushNotifications denied by user.');
    }
    this.onMessageHandlerUnsubscribe = this.notifications.onMessage(async (payload) => {
      console.info('Received in app notification:', payload);
      await this.pollNotificationsCount();
    });
    this.unregisterPushNotify = this.unregisterPushNotify.bind(this);
    this.pollNotificationsCount = this.pollNotificationsCount.bind(this);
    this.eventBus.on('logout', this.unregisterPushNotify);
    this.eventBus.on('poll-notifications', this.pollNotificationsCount);
  }

  /**
   * Unregisters push notifications
   */
  public async unregisterPushNotify() {
    if (!this.isSupported || !this.AppState.pushNotifyRegistered) {
      return;
    }
    const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
    if (!serviceWorkerRegistration) {
      console.error('No Service Worker available for PushNotifications un-registration');
      return;
    }
    await this.notifications.getToken({ vapidKey: firebaseConfig.vapidKey, serviceWorkerRegistration })
      .then((token) => this.mantis.delete(`/orgs/${this.currentOrgId}/push-tokens`, { data: { token } }))
      .catch((err) => console.error(err)); // suppress
    await this.notifications.deleteToken()
      .catch((err) => console.error(err)); // suppress
    this.AppState.setPushNotifyRegistered(false);
  }

  /**
   * Registers push notification tokens
   */
  public async registerPushNotifications() {
    if (!this.isSupported || !this.isRegistrationAvailable) {
      return;
    }
    try {
      const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
      if (!serviceWorkerRegistration) {
        console.error('No Service Worker available for PushNotifications registration');
        return;
      }
      if (navigator.serviceWorker.controller?.state !== 'activated') {
        console.error('Service Worker not activated, skip PushNotifications registration until worker is activated');
        return;
      }
      this.registerClicked = true;
      const token = await this.notifications.getToken({ vapidKey: firebaseConfig.vapidKey, serviceWorkerRegistration });
      await this.mantis.put(`/orgs/${this.currentOrgId}/push-tokens`, { token, userAgent: window.navigator.userAgent });
      this.AppState.setPushNotifyRegistered(true);
    } catch (err: any) {
      if (err.code === 'messaging/permission-blocked') {
        console.warn('PushNotifications permissions blocked by user');
        this.AppState.setPushNotifyRegistered(true);
      } else {
        console.error('Error registering PushNotification token', err);
        this.error(err);
      }
    }
  }
}

export default toNative(PushNotificationsGuard);
</script>
