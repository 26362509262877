<template>
  <Grid v-if="!loading" col="1" :md="allFeatures.includes(PropertyFeatureFlags.TICKETS) || allFeatures.includes(PropertyFeatureFlags.TASKS) ? 5 : 1">
    <Col col="1" md="3" class="md:mr-2" v-if="allFeatures.includes(PropertyFeatureFlags.TICKETS) || allFeatures.includes(PropertyFeatureFlags.TASKS)">
      <ContentTitle class="!mt-0">{{ $t('Maintenance Requests & Tasks') }}</ContentTitle>
      <SummaryCard class="overflow-hidden">
        <SummaryCardGrid col="12">
          <SummaryCardCol v-if="allFeatures.includes(PropertyFeatureFlags.TICKETS)" :col="allFeatures.includes(PropertyFeatureFlags.TICKETS) && allFeatures.includes(PropertyFeatureFlags.TASKS) ? 6 : 12" class="!p-0">
            <SummaryCardItem :value="newTickets ?? 0" @click="open('home.tickets.new')" :variant="newTickets ? 'danger' : 'dark'" faIcon="fa-person-circle-exclamation" :title="$t('New Requests')" />
          </SummaryCardCol>
          <SummaryCardCol v-if="allFeatures.includes(PropertyFeatureFlags.TICKETS)" :col="allFeatures.includes(PropertyFeatureFlags.TICKETS) && allFeatures.includes(PropertyFeatureFlags.TASKS) ? 6 : 12" class="!p-0">
            <SummaryCardItem :value="activeTickets ?? 0 " @click="open('home.tickets.ongoing')" :variant="activeTickets ? 'info' : 'dark'" faIcon="fa-person-running" :title="$t('Active Requests')" />
          </SummaryCardCol>
          <SummaryCardCol v-if="allFeatures.includes(PropertyFeatureFlags.TASKS)" :col="allFeatures.includes(PropertyFeatureFlags.TICKETS) && allFeatures.includes(PropertyFeatureFlags.TASKS) ? 6 : 12" class="border-t border-surface !p-0">
            <SummaryCardItem :value="overdueTasks ?? 0 " @click="open('home.tasks.overdue')" :variant="overdueTasks ? 'danger' : 'dark'" faIcon="clipboard-question" :title="$t('Overdue Tasks')" />
          </SummaryCardCol>
          <SummaryCardCol v-if="allFeatures.includes(PropertyFeatureFlags.TASKS)" :col="allFeatures.includes(PropertyFeatureFlags.TICKETS) && allFeatures.includes(PropertyFeatureFlags.TASKS) ? 6 : 12" class="border-t border-surface !p-0">
            <SummaryCardItem :value="confirmTasks ?? 0 " @click="open('home.tasks.done')" :variant="confirmTasks ? 'warning' : 'dark'" faIcon="check-double" :title="$t('Tasks To Review')" />
          </SummaryCardCol>
          <SummaryCardCol v-if="allFeatures.includes(PropertyFeatureFlags.TASKS)" :col="allFeatures.includes(PropertyFeatureFlags.TICKETS) && allFeatures.includes(PropertyFeatureFlags.TASKS) ? 6 : 12" class="border-t border-surface !p-0">
            <SummaryCardItem :value="activeTasks ?? 0" @click="open('home.tasks.ongoing')" :variant="activeTasks ? 'info' : 'dark'" faIcon="fa-clipboard" :title="$t('Ongoing Tasks')" />
          </SummaryCardCol>
          <SummaryCardCol v-if="allFeatures.includes(PropertyFeatureFlags.TASKS)" :col="allFeatures.includes(PropertyFeatureFlags.TICKETS) && allFeatures.includes(PropertyFeatureFlags.TASKS) ? 6 : 12" class="border-t border-surface !p-0">
            <SummaryCardItem :value="recurringTasks ?? 0" @click="open('home.tasks.recurring')" :variant="recurringTasks ? 'success' : 'danger'" faIcon="calendar" :title="$t('Periodic Tasks')" />
          </SummaryCardCol>
        </SummaryCardGrid>
      </SummaryCard>
    </Col>
    <Col col="1" md="2" class="mt-2 md:mt-0 md:ml-2">
      <ContentTitle class="md:!mt-0">{{ $t('Alarms') }}</ContentTitle>
      <SummaryCard>
        <SummaryCardGrid col="1">
          <SummaryCardCol col="1" class="!p-0 !border-x-0">
            <SummaryCardItem :value="criticalAlarms ?? 0 " @click="open('home.alarms.critical')" :variant="criticalAlarms ? 'danger' : 'dark'" faIcon="exclamation-triangle" :title="$t('Critical')" />
          </SummaryCardCol>
          <SummaryCardCol col="1" class="border-t border-b !border-x-0 border-surface !p-0">
            <SummaryCardItem :value="importantAlarms ?? 0 " @click="open('home.alarms.important')" :variant="importantAlarms ? 'warning' : 'dark'" faIcon="circle-exclamation" :title="$t('Important')" />
          </SummaryCardCol>
          <SummaryCardCol col="1" class="!p-0 !border-x-0">
            <SummaryCardItem :value="confirmAlarms ?? 0" @click="open('home.alarms.ack')" :variant="confirmAlarms ? 'warning' : 'dark'" faIcon="check-double" :title="$t('To Review')" />
          </SummaryCardCol>
        </SummaryCardGrid>
      </SummaryCard>
    </Col>
  </Grid>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Badge from 'primevue/badge';

import {
  Component, ComponentBase, Prop, PropType, toNative,
} from '@/component-base';
import { BlueprintFilter, FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import Col from '@/components/grid/col.vue';
import Grid from '@/components/grid/grid.vue';
import { BlueprintHelper, BlueprintOptions } from '@/components/helpers/blueprint';
import ContentTitle from '@/components/page/content-title.vue';
import SummaryCard from '@/components/summary-card/summary-card.vue';
import SummaryCardCol from '@/components/summary-card/summary-card-col.vue';
import SummaryCardGrid from '@/components/summary-card/summary-card-grid.vue';

import { PropertyFeatureFlags } from '@/store/modules/interfaces';
import {
  Task, TaskState, Ticket, TicketState,
} from '../../property-role/modules/beetle/interfaces';
import { Alarm, AlarmState } from '../../property-role/modules/measurements/interfaces';
import SummaryCardItem from './home-summary-card-item.vue';

@Component({
  components: {
    FaIcon,
    SummaryCard,
    SummaryCardGrid,
    SummaryCardCol,
    SummaryCardItem,
    ContentTitle,
    Badge,
    Grid,
    Col,
  },
})
class HomeSummary extends ComponentBase {
  @Prop({ type: Array as PropType<PropertyFeatureFlags[]>, required: false, default: () => [] })
  declare public allFeatures: PropertyFeatureFlags[];

  public PropertyFeatureFlags = PropertyFeatureFlags;

  public newTickets: number| null = null;

  public activeTickets: number| null = null;

  public recurringTasks: number| null = null;

  public overdueTasks: number| null = null;

  public activeTasks: number| null = null;

  public confirmTasks: number| null = null;

  public criticalAlarms: number| null = null;

  public importantAlarms: number| null = null;

  public confirmAlarms: number| null = null;

  public loading = false;

  public async created() {
    this.loading = true;
    await Promise.allSettled([
      this.getTicketsCount(),
      this.getTasksCount(),
      this.getAlarmsCount(),
    ]);
    this.loading = false;
  }

  public open(routeName: string) {
    this.$router.push({ name: routeName });
  }

  public async routeTasks() {
    let route = 'home.tasks';
    if (this.overdueTasks) {
      route = 'home.tasks.overdue';
    } else if (this.confirmTasks) {
      route = 'home.tasks.done';
    }
    await this.$router.push({ name: route });
  }

  public async routeTickets() {
    let route = 'home.tickets';
    if (this.newTickets) {
      route = 'home.tickets.new';
    }
    await this.$router.push({ name: route });
  }

  public async routeAlarms() {
    let route = 'home.alarms';
    if (this.criticalAlarms) {
      route = 'home.alarms.critical';
    } else if (this.confirmAlarms) {
      route = 'home.alarms.ack';
    }
    await this.$router.push({ name: route });
  }

  private async getTicketsCount() {
    try {
      const [newTickets, activeTickets] = await Promise.all([
        BlueprintHelper.getCount(this.ticketsOpts, this.newTicketsFilter),
        BlueprintHelper.getCount(this.ticketsOpts, this.activeTicketsFilter),
      ]);
      this.newTickets = newTickets;
      this.activeTickets = activeTickets;
    } catch (err) {
      this.error(err);
    }
  }

  private async getTasksCount() {
    try {
      const [recurringTasks, activeTasks, overdueTasks, confirmTasks] = await Promise.all([
        BlueprintHelper.getCount(this.tasksOpts, this.recurringTasksFilter),
        BlueprintHelper.getCount(this.tasksOpts, this.activeTasksFilter),
        BlueprintHelper.getCount(this.tasksOpts, this.overdueTasksFilter),
        BlueprintHelper.getCount(this.tasksOpts, this.confirmTasksFilter),
      ]);
      this.recurringTasks = recurringTasks;
      this.activeTasks = activeTasks;
      this.overdueTasks = overdueTasks;
      this.confirmTasks = confirmTasks;
    } catch (err) {
      this.error(err);
    }
  }

  private async getAlarmsCount() {
    try {
      const [criticalAlarms, importantAlarms, confirmAlarms] = await Promise.all([
        BlueprintHelper.getCount(this.alarmsOpts, this.criticalActiveAlarmsFilter),
        BlueprintHelper.getCount(this.alarmsOpts, this.nonCriticalAlarmsFilter),
        BlueprintHelper.getCount(this.alarmsOpts, this.alarmsConfirmFilter),
      ]);
      this.criticalAlarms = criticalAlarms;
      this.importantAlarms = importantAlarms;
      this.confirmAlarms = confirmAlarms;
    } catch (err) {
      this.error(err);
    }
  }

  public get ticketsOpts(): BlueprintOptions<Ticket> {
    return {
      http: this.beetle,
      apiRoutePath: '/orgs/:orgId/tickets',
      params: { orgId: String(this.currentOrgId) },
      fields: [{ field: 'id' }],
    };
  }

  public get tasksOpts(): BlueprintOptions<Task> {
    return {
      http: this.beetle,
      apiRoutePath: '/orgs/:orgId/tasks',
      params: { orgId: String(this.currentOrgId) },
      fields: [{ field: 'id' }],
    };
  }

  public get alarmsOpts(): BlueprintOptions<Alarm> {
    return {
      http: this.measurements,
      apiRoutePath: '/orgs/:orgId/alarms',
      params: { orgId: String(this.currentOrgId) },
      fields: [{ field: 'id' }],
    };
  }

  public recurringTasksFilter: BlueprintFilter = {
    state: {
      value: [TaskState.NEW, TaskState.CONFIRMED, TaskState.IN_PROGRESS, TaskState.DONE],
      matchMode: FilterMatchMode.IN,
    },
    recurringTaskId: {
      value: null,
      matchMode: FilterMatchMode.NOT_EQUALS,
    },
  };

  public activeTasksFilter: BlueprintFilter = {
    state: {
      value: [TaskState.NEW, TaskState.IN_PROGRESS, TaskState.CONFIRMED],
      matchMode: FilterMatchMode.IN,
    },
    recurringTaskId: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  };

  public overdueTasksFilter: BlueprintFilter = {
    state: {
      value: [TaskState.NEW, TaskState.IN_PROGRESS, TaskState.CONFIRMED],
      matchMode: FilterMatchMode.IN,
    },
    deadline: {
      value: new Date(),
      matchMode: FilterMatchMode.LESS_THAN,
    },
  };

  public confirmTasksFilter: BlueprintFilter = {
    state: {
      value: [TaskState.DONE],
      matchMode: FilterMatchMode.IN,
    },
  };

  public criticalActiveAlarmsFilter: BlueprintFilter = {
    state: {
      value: [AlarmState.ACTIVE],
      matchMode: FilterMatchMode.IN,
    },
    priority: {
      value: 1,
      matchMode: FilterMatchMode.GREATER_THAN,
    },
  };

  public nonCriticalAlarmsFilter: BlueprintFilter = {
    state: {
      value: [AlarmState.ACTIVE],
      matchMode: FilterMatchMode.IN,
    },
    priority: {
      value: 1,
      matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
    },
  };

  public alarmsConfirmFilter: BlueprintFilter = {
    state: {
      value: [AlarmState.RESOLVED],
      matchMode: FilterMatchMode.IN,
    },
  };

  public newTicketsFilter: BlueprintFilter = {
    state: {
      value: TicketState.NEW,
      matchMode: FilterMatchMode.EQUALS,
    },
  };

  public activeTicketsFilter: BlueprintFilter = {
    state: {
      value: [TicketState.IN_PROGRESS, TicketState.ON_HOLD, TicketState.OPEN, TicketState.RESOLVED],
      matchMode: FilterMatchMode.IN,
    },
  };
}

export default toNative(HomeSummary);
</script>
