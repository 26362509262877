/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tickets',
    name: 'properties.property.beetle.tickets',
    redirect: { name: 'properties.property.beetle.tickets.active' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tickets/active',
    name: 'properties.property.beetle.tickets.active',
    component: () => import('./tickets-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tickets/closed',
    name: 'properties.property.beetle.tickets.closed',
    component: () => import('./tickets-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tickets/:id',
    name: 'properties.property.beetle.tickets.ticket',
    component: () => import('./tickets-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tasks',
    name: 'properties.property.beetle.tasks',
    redirect: { name: 'properties.property.beetle.tasks.active' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tasks/active',
    name: 'properties.property.beetle.tasks.active',
    component: () => import('./tasks-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tasks/periodic',
    name: 'properties.property.beetle.tasks.periodic',
    component: () => import('./tasks-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tasks/closed',
    name: 'properties.property.beetle.tasks.closed',
    component: () => import('./tasks-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/tasks/:id',
    name: 'properties.property.beetle.tasks.task',
    component: () => import('./tasks-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/smart-docs',
    name: 'properties.property.beetle.smart-docs',
    component: () => import('./smart-docs-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/smart-docs/view-doc',
    name: 'properties.property.beetle.smart-docs.view-doc',
    component: () => import('./smart-docs-item.vue'),
  },
] as RouteRecordRaw[];
