<template>
  <div :key="String(currentOrgId)" class="main h-screen max-h-screen min-h-screen lg:flex lg:flex-auto select-text">
    <ProgressBar class="loadingbar" :class="{ 'loadingbar-visible': loading }" mode="indeterminate" />
    <SideMenu v-if="!loadingInternal" :menu="menu" :userMenu="userMenu" class="lg:flex hidden" />
    <div v-if="!loadingInternal" class="min-h-screen max-h-screen flex flex-col relative flex-auto">
      <router-view />
      <BottomMenu v-if="!loadingInternal" :menu="menu" :userMenu="userMenu" class="lg:hidden" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loadingbar.loadingbar-visible {
  opacity: 1 !important;
  z-index: 255;
}
.main {
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}
.loadingbar {
  transition: all .4s;
  border-radius: 0.5em;
  position: absolute;
  z-index: 1;
  width: 100vw;
  border-radius:0;
  height: .25rem;
  opacity: 0;
  transition: all 0.5sec;
}

</style>

<script lang="ts">
import { MenuItem } from 'primevue/menuitem';
import ProgressBar from 'primevue/progressbar';
import { RouteLocationNormalizedLoaded } from 'vue-router';

import {
  Component, ComponentBase, toNative, Watch,
} from '@/component-base';
import {
  OrgRolePermission, Property, PropertyFeatureFlags, PropertyRolePermission,
} from '@/store/modules/interfaces';

import { BlueprintFilter, FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import { BlueprintHelper, BlueprintOptions } from '@/components/helpers/blueprint';
import BottomMenu from './components/bottom-menu.vue';
import { MainLayoutBottomMenuItem, MainLayoutMenuItemRoute, MainLayoutSideMenuItem } from './components/interfaces';
import SideMenu from './components/side-menu.vue';

@Component({
  components: { SideMenu, BottomMenu, ProgressBar },
})
class MainLayout extends ComponentBase {
  public allFeatures: PropertyFeatureFlags[] = [];

  public PropertyFeatureFlags = PropertyFeatureFlags;

  public loadingInternal = false;

  @Watch('$route', { deep: true })
  public routeWatcher(newValue: RouteLocationNormalizedLoaded) {
    this.updateMenuRoutes(newValue);
  }

  public async created() {
    try {
      this.loadingInternal = true;
      const propertyRoleProperties = (this.currentOrgId && this.me)
        ? this.me.getOrg(this.currentOrgId)?.properties
          .filter((p) => p.hasPropertyRole(PropertyRolePermission.ADMIN) || p.hasPropertyRole(PropertyRolePermission.MANAGER))
          .map((p) => p.id) ?? []
        : [];
      if (!propertyRoleProperties.length) {
        return;
      }
      const filter: BlueprintFilter = {
        id: {
          matchMode: FilterMatchMode.IN,
          value: propertyRoleProperties,
        },
      };
      const { data: properties } = await BlueprintHelper.getMany(this.opts, filter, { limit: 100 });
      properties.data.forEach((p) => {
        if (p.featureFlags) {
          this.allFeatures.push(...p.featureFlags);
        }
      });
    } catch (err) {
      this.error(err);
    } finally {
      this.loadingInternal = false;
    }
  }

  private get opts(): BlueprintOptions<Property> {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties',
      params: { orgId: String(this.currentOrgId) },
    };
  }

  private propertiesRoute: MainLayoutMenuItemRoute | null = null;

  private homeRoute: MainLayoutMenuItemRoute | null = null;

  private reportsRoute: MainLayoutMenuItemRoute | null = null;

  public get loading() {
    return this.RequestsState.globalHttpRequestsPending || this.loadingInternal;
  }

  public updateMenuRoutes(route: RouteLocationNormalizedLoaded) {
    if (String(route.name).startsWith('properties')) {
      this.propertiesRoute = { name: String(route.name), params: route.params, query: route.query };
    }
    if (String(route.name).startsWith('home')) {
      this.homeRoute = { name: String(route.name), params: route.params, query: route.query };
    }
    if (String(route.name).startsWith('reports')) {
      this.reportsRoute = { name: String(route.name), params: route.params, query: route.query };
    }
  }

  public get userMenu(): MenuItem[] {
    const meOrg = this.currentOrgId ? this.me?.getOrg(this.currentOrgId) : null;
    const roles = this.currentOrgId && this.me ? this.me.orgRoleList(this.currentOrgId) : [];
    return [
      ...(this.AppState.me?.orgs?.length && this.AppState.me.orgs.length > 1) ? [{ label: this.$t('Change Organization'), icon: 'pi pi-sync', command: () => { this.$router.push({ name: 'auth.choose-org' }); } }] : [],
      ...(roles.length > 1) ? [{ label: this.$t('Change Role'), icon: 'pi pi-reply', command: () => { this.$router.push({ name: 'auth.choose-org-role' }); } }] : [],
      ...(roles.length > 1 && this.AppState.me?.orgs?.length && this.AppState.me.orgs.length > 1) ? [{ separator: true }] : [],
      //
      ...meOrg?.hasOrgRole(OrgRolePermission.ADMIN) ? [{ label: this.$t('Organization'), icon: 'pi pi-briefcase', command: () => { this.$router.push({ name: 'org', params: { orgId: this.currentOrgId } }); } }] : [],
      ...meOrg?.hasOrgRole(OrgRolePermission.ADMIN) ? [{ label: this.$t('Plan & Billing'), icon: 'pi pi-credit-card', command: () => { this.$router.push({ name: 'org.billing', params: { orgId: this.currentOrgId } }); } }] : [],
      ...meOrg?.hasOrgRole(OrgRolePermission.ADMIN) ? [{ label: this.$t('Managers'), icon: 'pi pi-users', command: () => { this.$router.push({ name: 'org.managers', params: { orgId: this.currentOrgId } }); } }] : [],
      ...meOrg?.hasOrgRole(OrgRolePermission.ADMIN) ? [{ separator: true }] : [],
      //
      { label: this.$t('Profile'), icon: 'pi pi-user', command: () => { this.$router.push({ name: 'user.profile', params: { orgId: this.currentOrgId } }); } },
      { label: this.$t('Sign Out'), icon: 'pi pi-power-off', command: () => { this.logout(); } },
    ];
  }

  public get menu(): (MainLayoutSideMenuItem & MainLayoutBottomMenuItem)[] {
    const meOrg = this.currentOrgId ? this.me?.getOrg(this.currentOrgId) : null;
    return [
      {
        name: this.$t('Home'),
        icon: 'house',
        href: { name: 'home', params: { orgId: this.currentOrgId } },
        historyHref: this.homeRoute,
      },
      ...meOrg?.hasOrgRole() ? [{
        name: this.$t('Buildings'),
        icon: 'building',
        href: { name: 'properties', params: { orgId: this.currentOrgId } },
        historyHref: this.propertiesRoute,
      }] : [],
      ...meOrg?.hasOrgRole(OrgRolePermission.ADMIN) && (this.allFeatures.includes(PropertyFeatureFlags.TASKS) || this.allFeatures.includes(PropertyFeatureFlags.TICKETS)) ? [{
        name: this.$t('Reports'),
        icon: 'chart-simple',
        href: { name: 'reports', params: { orgId: this.currentOrgId } },
        historyHref: this.reportsRoute,
      }] : [],
      {
        name: this.$t('Notifications'),
        icon: 'fa-regular fa-bell',
        ...this.AppState.unreadNotifications ? { badge: { severity: 'danger' } } : {},
        href: { name: 'notifications', params: { orgId: this.currentOrgId } },
      },
    ];
  }
}

export default toNative(MainLayout);
</script>
