import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LarCognitoConfig = _resolveComponent("LarCognitoConfig")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LarCognitoConfig, {
      cognitoRegion: _ctx.AuthConfig.region,
      cognitoPoolId: _ctx.AuthConfig.userPoolId,
      cognitoClientId: _ctx.AuthConfig.userPoolWebClientId
    }, null, 8, ["cognitoRegion", "cognitoPoolId", "cognitoClientId"]),
    (!_ctx.ready)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          class: "loadingbar",
          mode: "indeterminate"
        }))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
      : _createCommentVNode("", true)
  ]))
}