<!-- eslint-disable vue/max-len -->
<template>
  <div class="fadein overflow-hidden max-h-screen min-h-screen flex-shrink-0 static left-0 top-0 z-5 border-r border-surface w-auto">
    <div class="flex flex-col max-h-screen min-h-screen bg-primary-600 flex-shrink-0 select-none">
      <div class="p-3 bg-primary-600">
        <img class="logo" :src="require('@/assets/larva.svg')" alt="Fentrica">
      </div>
      <hr class="mb-3 mx-3 border-t border-0 border-primary-300">
      <div class="overflow-y-auto overscroll-y-auto">
        <ul class="list-none px-2 m-0">
          <li v-for="menuItem in mainMenu" :key="menuItem.name" class="mb-2">
            <a @keypress="route(menuItem.href, menuItem.historyHref)" @click="route(menuItem.href, menuItem.historyHref)" v-tooltip.right="menuItem.name" :to="menuItem.href" :class="{ 'text-white': isRouteActive(menuItem.href), 'bg-primary-400': isRouteActive(menuItem.href) }" class="max-w-24 no-underline text-center relative p-element flex flex-col items-center cursor-pointer p-2 justify-center hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors">
              <Badge v-if="menuItem.badge" :value="menuItem.badge.value" :severity="menuItem.badge.severity" class="badge" />
              <FaIcon class="mr-1 lg:mr-0" :class="{ 'fa-shake': menuItem.badge }" v-if="menuItem.icon" :icon="menuItem.icon" />
            </a>
          </li>
        </ul>
      </div>
      <div class="mt-auto">
        <hr class="mb-3 mx-3 border-t border-0 border-primary-300">
        <Menu ref="userMenu" :model="userMenu" :popup="true" style="width: 250px;" />
        <div class="py-3 px-2 m-0">
          <a @click.prevent="toggleUserMenu" @keypress="toggleUserMenu" v-tooltip.right="currentUserName" class="max-w-24 no-underline text-center p-element flex flex-col items-center cursor-pointer p-2 justify-center hover:bg-primary-400 rounded-md text-indigo-50 hover:text-white transition-duration-150 transition-colors">
            <FaIcon class="mr-1" icon="user-circle" style="margin-left: 4px;" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo { width: auto; max-width: 3rem; margin: 0 auto; }
.badge {
  position: absolute !important;
  font-size: 0.5rem !important;
  min-width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  top: 10px;
  right: 20%;
  transform: translate(50%,-50%);
  transform-origin: 100% 0;
  margin: 0;
  padding: 0 0.2rem !important;
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Badge from 'primevue/badge';
import Menu from 'primevue/menu';
import { MenuItem } from 'primevue/menuitem';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';

import { MainLayoutMenuItemRoute, MainLayoutSideMenuItem } from './interfaces';

@Component({
  components: { FaIcon, Badge, Menu },
})
class SideMenu extends ComponentBase {
  declare public $refs: { userMenu: InstanceType<typeof Menu> };

  @Prop({ type: Object as PropType<MenuItem[]>, required: false, default: () => [] })
  declare userMenu: MenuItem[];

  @Prop({ type: Object as PropType<MainLayoutSideMenuItem[]>, required: false, default: () => [] })
  declare menu: MainLayoutSideMenuItem[];

  public isRouteActive(href: MainLayoutMenuItemRoute): boolean {
    const activeRouteName = (this.$route.name ?? '') as string;
    return activeRouteName.startsWith(href.name);
  }

  public get mainMenu(): MainLayoutSideMenuItem[] {
    return this.menu.filter((e) => !e.hide);
  }

  public get currentUserName() {
    // @TODO: find user from roles
    return this.$t('User');
  }

  public async route(to: MainLayoutMenuItemRoute, historyTo?: MainLayoutMenuItemRoute | null) {
    // if we don't have history or we click again on same button, redirect to default
    if (!historyTo || String(this.$route.name).startsWith(to.name)) {
      await this.$router.push(to);
    } else {
      await this.$router.push(historyTo);
    }
  }

  public toggleUserMenu(event: Event) {
    this.$refs.userMenu.toggle(event);
  }
}

export default toNative(SideMenu);
</script>
