import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.priorityClass(_ctx.alarm.priority)], "whitespace-nowrap overflow-ellipsis overflow-hidden"])
  }, [
    _createVNode(_component_FaIcon, { icon: "triangle-exclamation" }),
    _createTextVNode(" " + _toDisplayString(_ctx.priorityLabel(_ctx.alarm.priority)), 1)
  ], 2))
}