/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

import PropertiesRoutes from './modules/properties/routes';

export default [
  {
    path: '/external-partner',
    name: 'external-partner',
    component: () => import('./layouts/external-partner.vue'),
    redirect: { name: 'init' },
    children: [
      {
        path: '/external-partner/orgs/:orgId',
        name: 'external-partner.home',
        redirect: { name: 'external-partner.properties' },
      },
      ...PropertiesRoutes,
    ],
  },
] satisfies RouteRecordRaw[];
