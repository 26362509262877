/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

export default [
  // access groups
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-groups',
    name: 'properties.property.mantis.access-groups',
    redirect: { name: 'properties.property.mantis.access-groups.policies' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-groups/list',
    name: 'properties.property.mantis.access-groups.list',
    component: () => import('./access-groups-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-policies',
    name: 'properties.property.mantis.access-groups.policies',
    component: () => import('./access-policy-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-groups/users',
    name: 'properties.property.mantis.access-groups.users',
    component: () => import('./access-policy-users-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-groups/cards',
    name: 'properties.property.mantis.access-groups.cards',
    component: () => import('./access-policy-card-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-groups/plates',
    name: 'properties.property.mantis.access-groups.plates',
    component: () => import('./access-policy-plate-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-groups/:id',
    name: 'properties.property.mantis.access-groups.access-group',
    component: () => import('./access-groups-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-policies/:id',
    name: 'properties.property.mantis.access-groups.access-policy',
    component: () => import('./access-policy-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-policies/:accessPolicyId/user/:id',
    name: 'properties.property.mantis.access-groups.access-policy.user',
    component: () => import('./access-policy-users-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-policies/:accessPolicyId/card/:id',
    name: 'properties.property.mantis.access-groups.access-policy.card',
    component: () => import('./access-policy-card-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/access-policies/:accessPolicyId/plate/:id',
    name: 'properties.property.mantis.access-groups.access-policy.plate',
    component: () => import('./access-policy-plate-item.vue'),
  },
  // logs
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/logs',
    name: 'properties.property.mantis.logs',
    component: () => import('./logs-list.vue'),
  },
] as RouteRecordRaw[];
