import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UndrawEmpty = _resolveComponent("UndrawEmpty")!
  const _component_FilterChips = _resolveComponent("FilterChips")!
  const _component_FilterBlueprintDropdown = _resolveComponent("FilterBlueprintDropdown")!
  const _component_TechnicalSystemListItem = _resolveComponent("TechnicalSystemListItem")!
  const _component_BlueprintDataView = _resolveComponent("BlueprintDataView")!

  return (_openBlock(), _createBlock(_component_BlueprintDataView, {
    noNew: "",
    id: "technical_systems",
    opts: _ctx.opts,
    title: _ctx.$t('My buildings technical systems')
  }, {
    empty: _withCtx(() => [
      _createVNode(_component_UndrawEmpty, {
        class: "mb-3",
        "primary-color": "var(--lar-color-primary)",
        height: "9rem"
      }),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('There are no technical systems linked in any building.')), 1)
    ]),
    "filter-type": _withCtx(({ filter }) => [
      _createVNode(_component_FilterChips, {
        modelValue: filter.value,
        "onUpdate:modelValue": ($event: any) => ((filter.value) = $event),
        options: _ctx.typesOptions
      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
    ]),
    "filter-propertyId": _withCtx(({ filter }) => [
      _createVNode(_component_FilterBlueprintDropdown, {
        opts: _ctx.propertyOpts,
        modelValue: filter.value,
        "onUpdate:modelValue": ($event: any) => ((filter.value) = $event),
        applyFilterLabel: _ctx.$t('Select Building')
      }, null, 8, ["opts", "modelValue", "onUpdate:modelValue", "applyFilterLabel"])
    ]),
    list: _withCtx(({ data }) => [
      _createVNode(_component_TechnicalSystemListItem, {
        onClick: ($event: any) => (_ctx.openTechnicalSystem(data)),
        technicalSystem: data
      }, null, 8, ["onClick", "technicalSystem"])
    ]),
    _: 1
  }, 8, ["opts", "title"]))
}