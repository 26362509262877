import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/external-partner/orgs/:orgId/properties',
    name: 'external-partner.properties',
    component: () => import('./list.vue'),
  },
  {
    path: '/external-partner/orgs/:orgId/properties/:propertyId',
    name: 'external-partner.properties.property',
    redirect: { name: 'external-partner.properties.property.home' },
    component: () => import('./layouts/property.vue'),
    children: [
      {
        path: '/external-partner/orgs/:orgId/properties/:propertyId/home',
        name: 'external-partner.properties.property.home',
        component: () => import('./home.vue'),
      },
      //
      {
        path: '/external-partner/orgs/:orgId/properties/:propertyId/tasks/:id',
        name: 'external-partner.tasks.task',
        component: () => import('./tasks-item.vue'),
      },
      {
        path: '/external-partner/orgs/:orgId/properties/:propertyId/technical-systems/:id',
        name: 'external-partner.technical-systems.technical-system',
        component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/measurements/technical-systems-item.vue'),
      },
      {
        path: '/external-partner/orgs/:orgId/properties/:propertyId/smart-docs',
        name: 'external-partner.smart-docs',
        component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/beetle/smart-docs-list.vue'),
      },
      {
        path: '/external-partner/orgs/:orgId/properties/:propertyId/smart-docs/view-doc',
        name: 'external-partner.smart-docs.view-doc',
        component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/beetle/smart-docs-item.vue'),
      },
      {
        path: '/external-partner/orgs/:orgId/properties/:propertyId/alarms/:id',
        name: 'external-partner.alarms.alarm',
        component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/measurements/alarms-item.vue'),
      },
      {
        path: '/external-partner/orgs/:orgId/properties/:propertyId/unit-control/:id',
        name: 'external-partner.units.unit-control',
        component: () => import('@/modules/roles/org-role-property-role/modules/property-role/modules/orgs/unit-control-item.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
