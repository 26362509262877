import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "avatar relative mr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GravatarImg = _resolveComponent("GravatarImg")!
  const _component_BlueprintItem = _resolveComponent("BlueprintItem")!
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.notification.log.associations.accessGroupUserId)
      ? (_openBlock(), _createBlock(_component_BlueprintItem, {
          key: 0,
          id: _ctx.notification.log.associations.accessGroupUserId,
          opts: _ctx.accessGroupUserOpts
        }, {
          value: _withCtx(({ item }) => [
            _createVNode(_component_GravatarImg, {
              firstname: item.firstname,
              lastname: item.lastname,
              email: item.email
            }, null, 8, ["firstname", "lastname", "email"])
          ]),
          _: 1
        }, 8, ["id", "opts"]))
      : (_ctx.notification.log.associations.accessGroupLicensePlateId)
        ? (_openBlock(), _createBlock(_component_BlueprintItem, {
            key: 1,
            id: _ctx.notification.log.associations.accessGroupLicensePlateId,
            opts: _ctx.accessGroupPlateAssociationOpts
          }, {
            value: _withCtx(({ item }) => [
              _createVNode(_component_GravatarImg, {
                firstname: item.firstname,
                lastname: item.lastname,
                email: item.email
              }, null, 8, ["firstname", "lastname", "email"])
            ]),
            _: 1
          }, 8, ["id", "opts"]))
        : (_ctx.notification.log.associations.accessGroupCardId)
          ? (_openBlock(), _createBlock(_component_BlueprintItem, {
              key: 2,
              id: _ctx.notification.log.associations.accessGroupCardId,
              opts: _ctx.accessGroupPlateAssociationOpts
            }, {
              value: _withCtx(({ item }) => [
                _createVNode(_component_GravatarImg, {
                  firstname: item.firstname,
                  lastname: item.lastname,
                  email: item.email
                }, null, 8, ["firstname", "lastname", "email"])
              ]),
              _: 1
            }, 8, ["id", "opts"]))
          : (_ctx.notification.log.associations.orgRoleId)
            ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                key: 3,
                id: _ctx.notification.log.associations.orgRoleId,
                opts: _ctx.usersOpts
              }, {
                value: _withCtx(({ item }) => [
                  _createVNode(_component_GravatarImg, {
                    firstname: item.firstname,
                    lastname: item.lastname,
                    email: item.email
                  }, null, 8, ["firstname", "lastname", "email"])
                ]),
                _: 1
              }, 8, ["id", "opts"]))
            : (_ctx.notification.log.associations.propertyRoleId)
              ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                  key: 4,
                  id: _ctx.notification.log.associations.propertyRoleId,
                  opts: _ctx.propertyRoleOpts
                }, {
                  value: _withCtx(({ item }) => [
                    _createVNode(_component_GravatarImg, {
                      firstname: item.firstname,
                      lastname: item.lastname,
                      email: item.email
                    }, null, 8, ["firstname", "lastname", "email"])
                  ]),
                  _: 1
                }, 8, ["id", "opts"]))
              : (_ctx.notification.log.associations.externalPartnerId)
                ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                    key: 5,
                    id: _ctx.notification.log.associations.externalPartnerId,
                    opts: _ctx.externalPartnersOpts
                  }, {
                    value: _withCtx(({ item }) => [
                      _createVNode(_component_GravatarImg, {
                        firstname: item.firstname,
                        lastname: item.lastname,
                        email: item.email
                      }, null, 8, ["firstname", "lastname", "email"])
                    ]),
                    _: 1
                  }, 8, ["id", "opts"]))
                : (_ctx.notification.log.associations.commentId && (_ctx.notification.log.associations.taskId || _ctx.notification.log.associations.ticketId))
                  ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                      key: 6,
                      id: _ctx.notification.log.associations.commentId,
                      opts: _ctx.commentsOpts(_ctx.notification.log.associations.ticketId, _ctx.notification.log.associations.taskId)
                    }, {
                      value: _withCtx(({ item }) => [
                        (item.externalData && (item.externalData.email || item.externalData.from))
                          ? (_openBlock(), _createBlock(_component_GravatarImg, {
                              key: 0,
                              email: item.externalData.email,
                              firstname: item.externalData.from?.split(' ')[0],
                              lastname: item.externalData.from?.split(' ')[1]
                            }, null, 8, ["email", "firstname", "lastname"]))
                          : (_openBlock(), _createBlock(_component_Avatar, {
                              key: 1,
                              shape: "circle",
                              class: "p-4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_FaIcon, {
                                  size: "xl",
                                  icon: "fa-regular fa-building"
                                })
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 1
                    }, 8, ["id", "opts"]))
                  : (_ctx.notification.log.associations.taskId)
                    ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                        key: 7,
                        id: _ctx.notification.log.associations.taskId,
                        opts: _ctx.tasksOpts
                      }, {
                        value: _withCtx(({ item }) => [
                          (item.externalData && (item.externalData.email || item.externalData.from))
                            ? (_openBlock(), _createBlock(_component_GravatarImg, {
                                key: 0,
                                email: item.externalData.email,
                                firstname: item.externalData.from?.split(' ')[0],
                                lastname: item.externalData.from?.split(' ')[1]
                              }, null, 8, ["email", "firstname", "lastname"]))
                            : (_openBlock(), _createBlock(_component_Avatar, {
                                key: 1,
                                shape: "circle",
                                class: "p-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_FaIcon, {
                                    size: "xl",
                                    icon: "fa-regular fa-building"
                                  })
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }, 8, ["id", "opts"]))
                    : (_ctx.notification.log.associations.ticketId)
                      ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                          key: 8,
                          id: _ctx.notification.log.associations.ticketId,
                          opts: _ctx.ticketsOpts
                        }, {
                          value: _withCtx(({ item }) => [
                            (item.externalData && (item.externalData.email || item.externalData.from))
                              ? (_openBlock(), _createBlock(_component_GravatarImg, {
                                  key: 0,
                                  email: item.externalData.email,
                                  firstname: item.externalData.from?.split(' ')[0],
                                  lastname: item.externalData.from?.split(' ')[1]
                                }, null, 8, ["email", "firstname", "lastname"]))
                              : (_openBlock(), _createBlock(_component_Avatar, {
                                  key: 1,
                                  shape: "circle",
                                  class: "p-4"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_FaIcon, {
                                      size: "xl",
                                      icon: "fa-regular fa-building"
                                    })
                                  ]),
                                  _: 1
                                }))
                          ]),
                          _: 1
                        }, 8, ["id", "opts"]))
                      : (_openBlock(), _createBlock(_component_Avatar, {
                          key: 9,
                          shape: "circle",
                          class: "p-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_FaIcon, {
                              size: "xl",
                              icon: "fa-regular fa-building"
                            })
                          ]),
                          _: 1
                        })),
    (_ctx.resourceIcon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 10,
          class: _normalizeClass(["resource-icon", _ctx.resourceClass])
        }, [
          _createVNode(_component_FaIcon, { icon: _ctx.resourceIcon }, null, 8, ["icon"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}