<template>
  <Page>
    <HomeHeader />
    <PageContent>
      <template v-if="!loading">
        <HomePropertyList :key="propertyRoleProperties.length" :propertyIds="propertyRoleProperties" />
        <HomeSummary v-if="!!propertyRoleProperties.length" :allFeatures="allFeatures" />
        <HomeTechnicalSystems v-if="!!propertyRoleProperties.length && allFeatures.includes(PropertyFeatureFlags.TECHNICAL_SYSTEMS)" class="mt-4" />
      </template>
    </PageContent>
  </Page>
</template>

<script lang="ts">

import { Component, ComponentBase, toNative } from '@/component-base';
import Col from '@/components/grid/col.vue';
import Grid from '@/components/grid/grid.vue';
import Page from '@/components/page/page.vue';
import PageContent from '@/components/page/page-content.vue';

import { BlueprintHelper, BlueprintOptions } from '@/components/helpers/blueprint';
import { BlueprintFilter, FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import { Property, PropertyFeatureFlags } from '@/store/modules/interfaces';
import { PropertyRolePermission } from '../property-role/modules/orgs/interfaces';
import HomeHeader from './components/home-header.vue';
import HomePropertyList from './components/home-property-list.vue';
import HomeSummary from './components/summary.vue';
import HomeTechnicalSystems from './components/technical-systems-list.vue';

@Component({
  components: {
    Page,
    PageContent,
    Grid,
    Col,
    HomeSummary,
    HomePropertyList,
    HomeTechnicalSystems,
    HomeHeader,
  },
})
class Home extends ComponentBase {
  public propertyRoleProperties: string[] = [];

  public loading = false;

  public allFeatures: PropertyFeatureFlags[] = [];

  public PropertyFeatureFlags = PropertyFeatureFlags;

  public async created() {
    this.loading = true;
    await this.fetchMe();
    this.propertyRoleProperties = (this.currentOrgId && this.me)
      ? this.me.getOrg(this.currentOrgId)?.properties
        .filter((p) => p.hasPropertyRole(PropertyRolePermission.ADMIN) || p.hasPropertyRole(PropertyRolePermission.MANAGER))
        .map((p) => p.id) ?? []
      : [];
    await this.getAllPropertyFeatures();
    this.loading = false;
  }

  private async getAllPropertyFeatures() {
    if (!this.propertyRoleProperties.length) {
      return;
    }
    const filter: BlueprintFilter = {
      id: {
        matchMode: FilterMatchMode.IN,
        value: this.propertyRoleProperties,
      },
    };
    const { data: properties } = await BlueprintHelper.getMany(this.opts, filter, { limit: 100 });
    properties.data.forEach((p) => {
      if (p.featureFlags) {
        this.allFeatures.push(...p.featureFlags);
      }
    });
  }

  private get opts(): BlueprintOptions<Property> {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties',
      params: { orgId: String(this.currentOrgId) },
    };
  }
}

export default toNative(Home);
</script>
