<template>
  <PageToolbar>
    <template #start>
      <div class="ml-1"><slot name="start" v-if="$slots.start" /></div>
    </template>
    <div class="flex items-center ml-2">
      <h1 v-if="currentOrg && !$slots.default" class="uppercase text-lg"><FaIcon icon="briefcase" class="mr-1" /> {{ currentOrg.name }}</h1>
      <PageTitle v-if="$slots.default"><slot /></PageTitle>
    </div>
    <template #end>
      <slot name="end" v-if="$slots.end" />
    </template>
  </PageToolbar>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

import { Component, ComponentBase, toNative } from '@/component-base';
import PageTitle from '@/components/page/page-title.vue';
import PageToolbar from '@/components/page/page-toolbar.vue';

@Component({
  components: {
    FaIcon,
    PageToolbar,
    PageTitle,
  },
})
class HomeHeader extends ComponentBase {

}

export default toNative(HomeHeader);
</script>
