import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/access-group-user/orgs/:orgId/properties',
    name: 'access-group-user.properties',
    component: () => import('./list.vue'),
  },
  {
    path: '/access-group-user/orgs/:orgId/properties/:propertyId',
    name: 'access-group-user.properties.property',
    redirect: { name: 'access-group-user.properties.property.home' },
    component: () => import('./layouts/property.vue'),
    children: [
      {
        path: '/access-group-user/orgs/:orgId/properties/:propertyId/home',
        name: 'access-group-user.properties.property.home',
        component: () => import('./home.vue'),
      },
      {
        path: '/access-group-user/orgs/:orgId/properties/:propertyId/tickets/:id',
        name: 'access-group-user.properties.property.tickets.ticket',
        component: () => import('./tickets-item.vue'),
      },
      {
        path: '/access-group-user/orgs/:orgId/properties/:propertyId/access-policies/:id',
        name: 'access-group-user.properties.property.policies.policy',
        component: () => import('./access-policy-item.vue'),
      },
      {
        path: '/access-group-user/orgs/:orgId/properties/:propertyId/access-policies/:accessPolicyId/users/:id',
        name: 'access-group-user.properties.property.policies.policy.users.user',
        component: () => import('./access-policy-user-item.vue'),
      },
      {
        path: '/access-group-user/orgs/:orgId/properties/:propertyId/access-policies/:accessPolicyId/cards/:id',
        name: 'access-group-user.properties.property.policies.policy.cards.card',
        component: () => import('./access-policy-card-item.vue'),
      },
      {
        path: '/access-group-user/orgs/:orgId/properties/:propertyId/access-policies/:accessPolicyId/license-plates/:id',
        name: 'access-group-user.properties.property.policies.policy.license-plates.license-plate',
        component: () => import('./access-policy-license-plate-item.vue'),
      },
      {
        path: '/access-group-user/orgs/:orgId/properties/:propertyId/smart-unit/:id',
        name: 'access-group-user.properties.property.smart-units.smart-unit',
        component: () => import('./smart-unit-item.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
