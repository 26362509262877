import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "notification-content" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = { key: 6 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "font-semibold" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 2,
  class: "flex items.center"
}
const _hoisted_19 = {
  key: 3,
  class: "flex items.center"
}
const _hoisted_20 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueprintItem = _resolveComponent("BlueprintItem")!
  const _component_AlarmPriorityBadge = _resolveComponent("AlarmPriorityBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      (_ctx.notification.log.associations.accessGroupUserId)
        ? (_openBlock(), _createBlock(_component_BlueprintItem, {
            key: 0,
            id: _ctx.notification.log.associations.accessGroupUserId,
            opts: _ctx.accessGroupUserOpts
          }, {
            value: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email) + " ", 1),
              _cache[0] || (_cache[0] = _createElementVNode("span", null, " ", -1))
            ]),
            _: 1
          }, 8, ["id", "opts"]))
        : (_ctx.notification.log.associations.accessGroupLicensePlateId)
          ? (_openBlock(), _createBlock(_component_BlueprintItem, {
              key: 1,
              id: _ctx.notification.log.associations.accessGroupLicensePlateId,
              opts: _ctx.accessGroupPlateAssociationOpts
            }, {
              value: _withCtx(({ item }) => [
                _createTextVNode(_toDisplayString(item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email) + " ", 1),
                _cache[1] || (_cache[1] = _createElementVNode("span", null, " ", -1))
              ]),
              _: 1
            }, 8, ["id", "opts"]))
          : (_ctx.notification.log.associations.accessGroupCardId)
            ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                key: 2,
                id: _ctx.notification.log.associations.accessGroupCardId,
                opts: _ctx.accessGroupPlateAssociationOpts
              }, {
                value: _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email) + " ", 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", null, " ", -1))
                ]),
                _: 1
              }, 8, ["id", "opts"]))
            : (_ctx.notification.log.associations.orgRoleId)
              ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                  key: 3,
                  id: _ctx.notification.log.associations.orgRoleId,
                  opts: _ctx.usersOpts
                }, {
                  value: _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email) + " ", 1),
                    _cache[3] || (_cache[3] = _createElementVNode("span", null, " ", -1))
                  ]),
                  _: 1
                }, 8, ["id", "opts"]))
              : (_ctx.notification.log.associations.propertyRoleId)
                ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                    key: 4,
                    id: _ctx.notification.log.associations.propertyRoleId,
                    opts: _ctx.propertyRoleOpts
                  }, {
                    value: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email) + " ", 1),
                      _cache[4] || (_cache[4] = _createElementVNode("span", null, " ", -1))
                    ]),
                    _: 1
                  }, 8, ["id", "opts"]))
                : (_ctx.notification.log.associations.externalPartnerId)
                  ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                      key: 5,
                      id: _ctx.notification.log.associations.externalPartnerId,
                      opts: _ctx.externalPartnersOpts
                    }, {
                      value: _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(item.firstname || item.lastname ? `${item.firstname} ${item.lastname}` : item.email) + " ", 1),
                        _cache[5] || (_cache[5] = _createElementVNode("span", null, " ", -1))
                      ]),
                      _: 1
                    }, 8, ["id", "opts"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      (_ctx.notification.log.associations.commentId && (_ctx.notification.log.associations.taskId || _ctx.notification.log.associations.ticketId))
                        ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                            key: 0,
                            id: _ctx.notification.log.associations.commentId,
                            opts: _ctx.commentsOpts(_ctx.notification.log.associations.ticketId, _ctx.notification.log.associations.taskId)
                          }, {
                            value: _withCtx(({ item }) => [
                              (item.externalData?.from || item.externalData?.email)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.externalData.from ?? item.externalData?.email), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('Unknown User')), 1)),
                              _cache[6] || (_cache[6] = _createElementVNode("span", null, " ", -1))
                            ]),
                            _: 1
                          }, 8, ["id", "opts"]))
                        : (_ctx.notification.log.associations.taskId)
                          ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                              key: 1,
                              id: _ctx.notification.log.associations.taskId,
                              opts: _ctx.tasksOpts
                            }, {
                              value: _withCtx(({ item }) => [
                                (item.externalData?.from || item.externalData?.email)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.externalData.from ?? item.externalData?.email), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('Unknown User')), 1)),
                                _cache[7] || (_cache[7] = _createElementVNode("span", null, " ", -1))
                              ]),
                              _: 1
                            }, 8, ["id", "opts"]))
                          : (_ctx.notification.log.associations.ticketId)
                            ? (_openBlock(), _createBlock(_component_BlueprintItem, {
                                key: 2,
                                id: _ctx.notification.log.associations.ticketId,
                                opts: _ctx.ticketsOpts
                              }, {
                                value: _withCtx(({ item }) => [
                                  (item.externalData?.from || item.externalData?.email)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.externalData.from ?? item.externalData?.email), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('Unknown User')), 1)),
                                  _cache[8] || (_cache[8] = _createElementVNode("span", null, " ", -1))
                                ]),
                                _: 1
                              }, 8, ["id", "opts"]))
                            : (_ctx.notification.log.associations.alarmId)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('Unknown User')) + " ", 1),
                                  _cache[9] || (_cache[9] = _createElementVNode("span", null, " ", -1))
                                ], 64))
                    ]))
    ]),
    _createElementVNode("span", null, [
      _createElementVNode("span", null, _toDisplayString(_ctx.getEventMessage(_ctx.notification.log.code)), 1),
      _cache[10] || (_cache[10] = _createElementVNode("span", null, " ", -1))
    ]),
    _createVNode(_component_BlueprintItem, {
      class: "font-semibold",
      id: _ctx.notification.log.propertyId,
      opts: _ctx.propertyOpts
    }, {
      value: _withCtx(({ item }) => [
        _createTextVNode(_toDisplayString(item.name), 1)
      ]),
      _: 1
    }, 8, ["id", "opts"]),
    _createElementVNode("span", null, [
      (_ctx.notification.log.associations.ticketId)
        ? (_openBlock(), _createBlock(_component_BlueprintItem, {
            key: 0,
            class: "pr-1 whitespace-nowrap overflow-ellipsis overflow-hidden",
            id: _ctx.notification.log.associations.ticketId,
            opts: _ctx.ticketsOpts
          }, {
            value: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.$t('Maintenance Request')) + " ", 1),
              _createElementVNode("span", _hoisted_10, "#" + _toDisplayString(item.number) + " - " + _toDisplayString(item.title), 1),
              (_ctx.notification.log.associations.commentId)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                    _cache[11] || (_cache[11] = _createElementVNode("span", null, " ", -1)),
                    _createTextVNode(_toDisplayString(_ctx.$t('comment')) + ".", 1)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, " "))
            ]),
            _: 1
          }, 8, ["id", "opts"]))
        : _createCommentVNode("", true),
      (_ctx.notification.log.associations.taskId)
        ? (_openBlock(), _createBlock(_component_BlueprintItem, {
            key: 1,
            class: "pr-1 whitespace-nowrap overflow-ellipsis overflow-hidden",
            id: _ctx.notification.log.associations.taskId,
            opts: _ctx.tasksOpts
          }, {
            value: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.$t('Maintenance Task')) + " ", 1),
              _createElementVNode("span", _hoisted_13, "#" + _toDisplayString(item.number) + " - " + _toDisplayString(item.name), 1),
              (_ctx.notification.log.associations.commentId)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                    _cache[12] || (_cache[12] = _createElementVNode("span", null, " ", -1)),
                    _createTextVNode(_toDisplayString(_ctx.$t('comment')) + ".", 1)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_15, " "))
            ]),
            _: 1
          }, 8, ["id", "opts"]))
        : _createCommentVNode("", true),
      (_ctx.notification.log.associations.alarmId)
        ? (_openBlock(), _createBlock(_component_BlueprintItem, {
            key: 2,
            class: "pr-1 whitespace-nowrap overflow-ellipsis overflow-hidden",
            id: _ctx.notification.log.associations.alarmId,
            opts: _ctx.alarmsOpts
          }, {
            value: _withCtx(({ item }) => [
              _createElementVNode("div", null, [
                _createVNode(_component_AlarmPriorityBadge, { alarm: item }, null, 8, ["alarm"]),
                _cache[13] || (_cache[13] = _createTextVNode("  ")),
                (item.analyticAlarmDefinition)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(item.analyticAlarmDefinition.name) + "  ", 1))
                  : _createCommentVNode("", true),
                (item.deviceAlarmDefinition)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(item.deviceAlarmDefinition.name) + "  ", 1))
                  : _createCommentVNode("", true),
                (item.backgroundJobId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createTextVNode(_toDisplayString(_ctx.$t('Job')) + " ", 1),
                      _createVNode(_component_BlueprintItem, {
                        class: "ml-1 inline-block",
                        id: item.backgroundJobId,
                        opts: _ctx.backgroundJobsOpts
                      }, null, 8, ["id", "opts"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('error')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (item.serviceConnectionId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createTextVNode(_toDisplayString(_ctx.$t('App integration')) + " ", 1),
                      _createVNode(_component_BlueprintItem, {
                        class: "ml-1 inline-block",
                        id: item.serviceConnectionId,
                        opts: _ctx.serviceConnectionOpts
                      }, null, 8, ["id", "opts"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('error')), 1)
                    ]))
                  : _createCommentVNode("", true),
                _cache[14] || (_cache[14] = _createElementVNode("span", null, " ", -1))
              ])
            ]),
            _: 1
          }, 8, ["id", "opts"]))
        : _createCommentVNode("", true),
      (_ctx.notification.log.data?.changed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.notification.log.data.changed.filter((f) => f !== 'updatedAt' && f !== 'orgRoleId').map((f) => _ctx.getChangeLabel(f)).join(', ')) + ".", 1))
        : _createCommentVNode("", true)
    ])
  ]))
}