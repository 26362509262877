<template>
  <DataCard :faIcon="icon">
    <template #content>
      <div class="ml-1 overflow-hidden">
        <p class="m-0 p-0 line-clamp-1 text-surface-800">
          <BlueprintItem v-if="technicalSystem.propertyId" class="inline-block" :id="technicalSystem.propertyId" faIcon="building" :opts="propertiesOpts" />
          <span class="whitespace-nowrap inline-block" v-if="technicalSystem.unitId">•&nbsp;</span>
          <BlueprintItem v-if="technicalSystem.propertyId && technicalSystem.unitId" class="inline-block" :id="technicalSystem.unitId" :opts="unitOpts(technicalSystem.propertyId)" />
        </p>
        <h3 class="mt-1 p-0 m-0 block whitespace-nowrap overflow-ellipsis overflow-hidden">
          {{ technicalSystem.name }}
        </h3>
        <p class="m-0 p-0 line-clamp-2 text-surface-800">
          {{ technicalSystem.description }}
        </p>
        <p class="m-0 pt-1 line-clamp-2 text-surface-800">
          <span class="capitalize">{{ $t(technicalSystem.type) }}</span>
          <span class="whitespace-nowrap inline-block" v-if="technicalSystem.label">&nbsp;•&nbsp;</span>
          {{ technicalSystem.label }}
        </p>
      </div>
    </template>
    <template #end>
      <div v-if="alarmCount" class="flex h-full mr-1 items-center">
        <FaIcon icon="warning" class="text-3xl warning" />
      </div>
    </template>
  </DataCard>
</template>

<style lang="scss" scoped>
.warning {
  color: var(--lar-color-warning);
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';
import BlueprintItem from '@/components/blueprint/blueprint-item.vue';
import { BlueprintFilter, FilterMatchMode } from '@/components/blueprint/interfaces/blueprint-data';
import Item from '@/components/blueprint/item.vue';
import DataCard from '@/components/data-card/data-card.vue';
import Col from '@/components/grid/col.vue';
import Grid from '@/components/grid/grid.vue';
import { BlueprintHelper, BlueprintOptions } from '@/components/helpers/blueprint';

import {
  Alarm, AlarmState, TechnicalSystem, technicalSystemIcon,
} from '../interfaces';
import AlarmPriorityBadge from './alarm-priority-badge.vue';
import AlarmSateBadge from './alarm-state-badge.vue';

@Component({
  components: {
    FaIcon,
    BlueprintItem,
    Item,
    DataCard,
    Grid,
    Col,
    AlarmPriorityBadge,
    AlarmSateBadge,
  },
})
class TechnicalSystemListItem extends ComponentBase {
  @Prop({ type: Object as PropType<TechnicalSystem>, required: true })
  declare public technicalSystem: TechnicalSystem;

  public alarmCount = 0;

  public async created() {
    try {
      const count = await BlueprintHelper.getCount(this.alarmsOpts(this.technicalSystem.propertyId), this.alarmsFilters);
      this.alarmCount = count ?? 0;
    } catch (err) {
      this.error(err);
    }
  }

  public get icon(): string {
    return technicalSystemIcon(this.technicalSystem.type);
  }

  public get propertiesOpts(): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties',
      params: { orgId: String(this.currentOrgId) },
      fields: [
        { field: 'id' },
        { field: 'name' },
      ],
    };
  }

  public get alarmsFilters(): BlueprintFilter {
    return {
      technicalSystemId: {
        value: this.technicalSystem.id,
        matchMode: FilterMatchMode.EQUALS,
      },
      state: {
        value: AlarmState.OK,
        matchMode: FilterMatchMode.NOT_EQUALS,
      },
    };
  }

  public alarmsOpts(propertyId: string): BlueprintOptions<Alarm> {
    return {
      http: this.measurements,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/alarms',
      params: { orgId: String(this.currentOrgId), propertyId },
      fields: [
        { field: 'id' },
      ],
    };
  }

  public unitOpts(propertyId: string): BlueprintOptions {
    return {
      http: this.orgs,
      apiRoutePath: '/orgs/:orgId/properties/:propertyId/units',
      params: { orgId: String(this.currentOrgId), propertyId },
      fields: [
        { field: 'id' },
        { field: 'name' },
      ],
    };
  }
}

export default toNative(TechnicalSystemListItem);
</script>
