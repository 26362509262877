import { RouteRecordRaw } from 'vue-router';

import OrgRoutes from './org/routes';
import PropertiesRoutes from './properties/routes';
import ReportsRoutes from './reports/routes';

export default [
  ...OrgRoutes,
  ...ReportsRoutes,
  ...PropertiesRoutes,
] satisfies RouteRecordRaw[];
