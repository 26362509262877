import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import GeneralConfig from '@/config/general';

// we use 2 sec so if we have same resources on same page, we wont re-fetch those
// if longer caches to be used, cache control must be implemented. Eg. if new resource is created or existing modified
// then all related caches must be revoked.
// @TODO: implement cache control to blueprint components
const CACHE_TTL = 1000 * 2 + 500; // 2 sec + 500 ms

const API_REQUEST_DEFAULT_HEADERS = {
  Accept: 'application/json',
};
const API_REQUEST_TIMEOUT = 30000;
const AXIOS_CONFIG = {
  timeout: API_REQUEST_TIMEOUT,
  headers: {
    ...API_REQUEST_DEFAULT_HEADERS,
  },
};

export type BuildingHttpClient = AxiosInstance;

function createHttpClient({ baseURL }: { baseURL: string }): BuildingHttpClient {
  const axiosInstance = setupCache(axios.create({ ...AXIOS_CONFIG, baseURL }), { ttl: CACHE_TTL });
  return Object.assign(axiosInstance);
}

export default class Http {
  private abortController: AbortController = new AbortController();

  public readonly mantis = createHttpClient({ baseURL: GeneralConfig.mantisURL });

  public readonly hive = createHttpClient({ baseURL: GeneralConfig.hiveURL });

  public readonly orgs = createHttpClient({ baseURL: GeneralConfig.orgsURL });

  public readonly beetle = createHttpClient({ baseURL: GeneralConfig.beetleURL });

  public readonly measurements = createHttpClient({ baseURL: GeneralConfig.measurementsUrl });

  static cachedToken: { token: string; decoded: JwtPayload } | null = null;

  constructor() {
    [this.mantis, this.hive, this.orgs, this.measurements, this.beetle].forEach((http) => {
      // remove any if fixed: https://github.com/axios/axios/issues/5573
      http.interceptors.request.use((Http.axiosTokenInterceptor as any), (err: AxiosError) => Promise.reject(err));
      http.interceptors.response.use((res) => res, Http.axiosResponseErrorInterceptor);
    });
  }

  public get signal() {
    return this.abortController.signal;
  }

  /**
   * abort all connections that use "signal" getter
   * @param reason status code or text why to aport
   */
  public abort(reason?: number | string) {
    this.abortController.abort(reason);
    this.abortController = new AbortController();
  }

  static async axiosTokenInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const headers: NonNullable<AxiosRequestConfig['headers']> = config.headers || {};
    try {
      const token = await Http.getAccessToken();
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers = config.headers ?? {};
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return config;
    } finally {
      // eslint-disable-next-line no-param-reassign
      config.headers = headers;
    }
  }

  static async getAccessToken() {
    if (Http.cachedToken?.token && Http.cachedToken?.decoded.exp && (Http.cachedToken.decoded.exp) > (Date.now() / 1000)) {
      return Http.cachedToken.token;
    }
    const Cognito = document.querySelector<HTMLLarCognitoConfigElement>('lar-cognito-config');
    if (!Cognito) {
      throw new Error('lar-cognito-config not loaded');
    }
    const token = await Cognito.getAccessToken();
    if (!token) {
      throw new Error('Unauthorized');
    }
    if (Http.cachedToken?.token && Http.cachedToken?.decoded.exp && (Http.cachedToken.decoded.exp) > (Date.now() / 1000)) {
      // async another request already handled token, return cached token
      return Http.cachedToken.token;
    }
    const decoded = jwtDecode(token);
    if (!decoded.exp) {
      throw new Error('Invalid token');
    }
    console.warn('Renewing token... New Token expires', decoded.exp * 1000, new Date((decoded.exp) * 1000), 'and now is', Date.now(), new Date());
    Http.cachedToken = { token, decoded };
    return token;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static axiosResponseErrorInterceptor(err: any) {
    if (err.code === 'ERR_CANCELED') { // axios CanceledError
      return Promise.resolve({ data: {} }); // resolve promise with dummy data, since our router cancels all the request on route change
    } if (err?.response?.status === 401 && err.config.skipAuthRedirect !== true && !window.location.pathname.startsWith('/auth/login')) {
      window.location.href = `/auth/login?url=${window.location.pathname}`;
    } else if (err?.response?.status > 499 && err?.response?.status !== 502 && !window.location.pathname.startsWith('/unexpected-error')) { // 502 is used for example in integrations to check is gateway available
      window.location.href = '/unexpected-error';
    } else if (err?.response?.status === 404 && err.config.skipNotFoundRedirect !== true && !window.location.pathname.startsWith('/404')) {
      window.location.href = '/404';
    } else if (!err?.response && err?.code === 'ERR_NETWORK') {
      window.dispatchEvent(new CustomEvent('offline')); // index.html is listening offline event
    }
    return Promise.reject(err);
  }
}
